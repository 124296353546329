import React from 'react';

export const useHandleOnMenuItemClick = (
  disabled: boolean,
  onMenuItemClick: Function,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return React.useCallback(
    (event: any) => {
      if (disabled) return;
      event.stopPropagation(); // Prevent the Select component from opening
      event.preventDefault();
      onMenuItemClick(event);
      setIsOpen(false);
    },
    [disabled]
  );
};

export const useHandleOnSelectClick = (
  disabled: boolean,
  onSelectClick: Function,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  isOpen: boolean
) => {
  return React.useCallback(
    (event: any) => {
      if (disabled) return;
      event.stopPropagation(); // Prevent the Select component from opening
      event.preventDefault();
      onSelectClick(event);
      setIsOpen(false);
    },
    [isOpen, disabled]
  );
};

export const useHandleOnClick = (
  disabled: boolean,
  onButtonClick: Function,
  isOpen: boolean
) => {
  return React.useCallback(
    (event: any) => {
      if (disabled) return;
      event.stopPropagation(); // Prevent the Select component from opening
      event.preventDefault();
      onButtonClick();
    },
    [isOpen, disabled]
  );
};

export const useHandleToggleDropdown = (
  disabled: boolean,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return React.useCallback(
    (event: any) => {
      if (disabled) return;
      event.stopPropagation(); // Prevent the Select component from opening
      event.preventDefault();
      setIsOpen((prev) => !prev);
    },
    [disabled]
  );
};
