/* eslint-disable import/no-cycle */
import {
  combineReducers,
  combineSlices,
  configureStore,
} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {
  modalSlice,
  topNavSlice,
  notificationSlice,
  procurementToDepotSlice,
  assetManagementSlice,
  orderManagementSlice,
  assetsSlice,
  ordersSlice,
  manageUsersSlice,
  globalSlice,
  homeSlice,
} from './slices';

import { homeUISlice } from './slices/ui/home';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const rootReducer = combineReducers({
  modal: modalSlice.reducer,
  topNav: topNavSlice.reducer,
  notification: notificationSlice.reducer,
  procurementToDepot: procurementToDepotSlice.reducer,
  assetManagement: assetManagementSlice.reducer,
  global: globalSlice.reducer,
  assets: assetsSlice,
  orders: ordersSlice,
  home: homeSlice,
  manageUsers: manageUsersSlice,
  ui: combineSlices({
    home: homeUISlice.reducer,
  }),
  orderManagement: orderManagementSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
