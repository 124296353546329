export const ContainerSx = {
  display: 'flex',
  mt: '30px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '30px',
  width: '100%',
  fontWeight: '600',
  fontSize: '14px',
  color: 'rgba(19, 20, 20, 1)',
};

export const CTAContentSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
};

export const CTAHeaderSx = {
  mt: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  fontSize: '16px',
  fontWeight: '700',
};

export const CTASubHeaderSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: '400',
  width: '100%',
  pb: '10px',
};

export const CTAIconButtonContainerSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: '400',
  width: '100%',
  pb: '10px',
};

export const CTAIconButtonSx = {
  mt: '20px',
  borderRadius: '30px',
  width: '140px',
  fontSize: '14px',
  backgroundColor: 'rgba(249, 197, 77, 1)',
};
