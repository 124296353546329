import theme from 'themes/theme';

export const DropdownSx = {
  '& .MuiOutlinedInput-input': {
    pl: 0,
    width: '166px',
  },
  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `1.5px solid ${theme.palette.colors.chiGong}`,
    overflow: 'visible',
    top: '-3px',
    bottom: '3px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};
