import TextCell from './TextCell';
import EmailAddressCell from './EmailAddressCell';
import DropdownCell from './DropdownCell';
import ButtonActionCell from './ButtonActionCell';
import ButtonActionOrDropdownCell from './ButtonActionOrDropdownCell';
import { DataGridProProps } from '@mui/x-data-grid-pro';

import { CellType } from './types';

const cells: Record<CellType, DataGridProProps['columns'][0]['renderCell']> = {
  'text-cell': TextCell,
  'email-address-cell': EmailAddressCell,
  'dropdown-cell': DropdownCell,
  'button-action-cell': ButtonActionCell,
  'button-action-or-dropdown-cell': ButtonActionOrDropdownCell,
};

export default cells;
