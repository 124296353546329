import React from 'react';
import { SVGProps } from '../../types';

const IntakeIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#4DB7C6" />
      <path
        d="M18.6668 18V8.66667L12.0002 6L5.3335 8.66667V18H9.3335V12.6667H14.6668V18H18.6668ZM11.3335 16.6667H10.0002V18H11.3335V16.6667ZM12.6668 14.6667H11.3335V16H12.6668V14.6667ZM14.0002 16.6667H12.6668V18H14.0002V16.6667Z"
        fill="white"
      />
    </svg>
  );
};

export default IntakeIcon;
