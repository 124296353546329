import React from 'react';
import { CustomGridColDef } from 'components/table/Table/types';
import {
  PRIMARY_ADMIN_COPY,
  SECONDARY_ADMIN_COPY,
} from '../../shared/constants';

import { CREDENTIALED_COLLABORATOR_ROLES } from 'globals/constants/credentialedCollaborators';
import { CredentialStatus } from 'api/rest/credentialedCollaborators/types';

const { EXTERNAL_PRIMARY_ADMINISTRATOR, EXTERNAL_SECONDARY_ADMINISTRATOR } =
  CREDENTIALED_COLLABORATOR_ROLES;

export const RESEND_INVITE = 'RESEND_INVITE';
export const REVOKE_ACCESS = 'REVOKE_ACCESS';

export const COLUMNS: CustomGridColDef[] = [
  {
    field: 'name',
    headerName: 'Admin name',
    width: 200,
    cellType: 'text-cell',
    sortable: false,
  },
  {
    field: 'email',
    headerName: 'Work Email',
    width: 200,
    sortable: false,
    cellType: 'email-address-cell',
  },
  {
    field: 'authenticationModes',
    headerName: 'Login method',
    width: 200,
    sortable: false,
    cellType: 'text-cell',
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 200,
    sortable: false,
    cellType: 'dropdown-cell',
    header: {
      headerType: 'tooltip-header',
      data: {
        description: (
          <div>
            {PRIMARY_ADMIN_COPY}
            <br />
            <br />
            {SECONDARY_ADMIN_COPY}
          </div>
        ),
      },
    },
  },
  {
    field: 'credentialStatus',
    headerName: 'Status',
    width: 200,
    sortable: false,
    cellType: 'text-cell',
  },
  {
    field: 'action',
    sortable: false,
    headerName: 'Action',
    width: 200,
    cellType: 'button-action-or-dropdown-cell',
  },
];

export const ROLE_OPTIONS = [
  {
    value: EXTERNAL_PRIMARY_ADMINISTRATOR,
    label: 'Primary admin',
  },
  {
    value: EXTERNAL_SECONDARY_ADMINISTRATOR,
    label: 'Secondary admin',
  },
];

export const ACTION_OPTIONS = [
  {
    value: RESEND_INVITE,
    label: 'Resend invite',
  },
  {
    value: REVOKE_ACCESS,
    label: 'Revoke access',
  },
];

export const STATUS_COLOR_MAP: Record<CredentialStatus, string> = {
  ACTIVE: 'colors.goldFusion',
  INACTIVE: 'black',
  PENDING: 'black',
  EXPIRED: 'colors.burntSiennaR',
};
