import React from 'react';
import { useDispatch } from 'react-redux';
import { setManageUsersShowInviteAdminModal } from 'store/slices/manage_users/actions';

export const useHandleOnInviteAdminOpenClick = () => {
  const dispatch = useDispatch();
  return React.useCallback(() => {
    dispatch(setManageUsersShowInviteAdminModal(true));
  }, []);
};

export const useHandleOnInviteAdminCloseClick = () => {
  const dispatch = useDispatch();
  return React.useCallback(() => {
    dispatch(setManageUsersShowInviteAdminModal(false));
  }, []);
};
