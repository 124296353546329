import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationSlice } from 'store/slices';
import {
  selectGetCredentialedCollaboratorsLoadingState,
  selectPatchCredentialedCollaboratorsLoadingState,
  selectPostCredentialedCollaboratorsErrorCode,
  selectPostCredentialedCollaboratorsLoadingState,
  selectRoleConfirmationData,
} from 'store/slices/manage_users/table/selectors';
import { CREDENTIALED_COLLABORATOR_ROLES } from 'globals/constants/credentialedCollaborators';
import { resetState } from 'store/slices/manage_users/table';

const { EXTERNAL_PRIMARY_ADMINISTRATOR, EXTERNAL_SECONDARY_ADMINISTRATOR } =
  CREDENTIALED_COLLABORATOR_ROLES;

export const useShowNotifications = () => {
  const dispatch = useDispatch();
  const getCredentialedCollaboratorsLoadingState = useSelector(
    selectGetCredentialedCollaboratorsLoadingState
  );
  const patchCredentialedCollaboratorsLoadingState = useSelector(
    selectPatchCredentialedCollaboratorsLoadingState
  );
  const postCredentialedCollaboratorsLoadingState = useSelector(
    selectPostCredentialedCollaboratorsLoadingState
  );
  const roleConfirmationData = useSelector(selectRoleConfirmationData);
  const postCredentialedCollaboratorsErrorCode = useSelector(selectPostCredentialedCollaboratorsErrorCode);
  React.useEffect(() => {
    if (getCredentialedCollaboratorsLoadingState === 'rejected') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: 'Error getting admins.  Please try again.',
          verticalOrigin: 'bottom',
          horizontalOrigin: 'right',
        })
      );
    }
  }, [getCredentialedCollaboratorsLoadingState]);

  React.useEffect(() => {
    if (patchCredentialedCollaboratorsLoadingState === 'rejected') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: 'Error saving changes to admin.  Please try again.',
          verticalOrigin: 'bottom',
          horizontalOrigin: 'right',
        })
      );
    }
    if (
      patchCredentialedCollaboratorsLoadingState === 'fulfilled' &&
      [
        EXTERNAL_PRIMARY_ADMINISTRATOR,
        EXTERNAL_SECONDARY_ADMINISTRATOR,
      ].includes(roleConfirmationData.role)
    ) {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: 'Role was successfully changed.',
          verticalOrigin: 'bottom',
          horizontalOrigin: 'right',
        })
      );
    }
    if (
      patchCredentialedCollaboratorsLoadingState === 'fulfilled' &&
      roleConfirmationData.role === null
    ) {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: 'Access was successfully revoked.',
          verticalOrigin: 'bottom',
          horizontalOrigin: 'right',
        })
      );
    }
  }, [patchCredentialedCollaboratorsLoadingState]);

  React.useEffect(() => {
    if (postCredentialedCollaboratorsLoadingState === 'rejected' && postCredentialedCollaboratorsErrorCode === 409) {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: 'Cannot re-send invite: user has previously accepted an invite.',
          verticalOrigin: 'bottom',
          horizontalOrigin: 'right',
        })
      );
    } else if (postCredentialedCollaboratorsLoadingState === 'rejected' && postCredentialedCollaboratorsErrorCode === 200) {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: 'Something went wrong. Please try again.',
          verticalOrigin: 'bottom',
          horizontalOrigin: 'right',
        })
      );
    }
    if (postCredentialedCollaboratorsLoadingState === 'fulfilled') {
      dispatch(
        notificationSlice.actions.setNotice({
          showNotice: true,
          noticeContent: 'Invite was successfully sent',
          verticalOrigin: 'bottom',
          horizontalOrigin: 'right',
        })
      );
    }
  }, [postCredentialedCollaboratorsLoadingState]);
};

export const useResetStateOnUnmount = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);
};
