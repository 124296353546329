import { createSlice } from '@reduxjs/toolkit';

import {
  getCredentialedCollaborators,
  patchCredentialedCollaborators,
  postCredentialedCollaborators,
} from './actions';
import {
  getCredentialedCollaboratorsPendingReducer,
  getCredentialedCollaboratorsFulfilledReducer,
  getCredentialedCollaboratorsRejectedReducer,
  patchCredentialedCollaboratorsPendingReducer,
  patchCredentialedCollaboratorsFulfilledReducer,
  patchCredentialedCollaboratorsRejectedReducer,
  postCredentialedCollaboratorsPendingReducer,
  postCredentialedCollaboratorsFulfilledReducer,
  postCredentialedCollaboratorsRejectedReducer,
  setPaginationModelReducer,
  setRoleConfirmationDataReducer,
  setShowRoleChangeConfirmationModalReducer,
  setShowRevokeConfirmationModalReducer,
  setPostCredentialedCollaboratorsLoadingStateReducer,
  setButtonActionDropdownByIdReducer,
} from './reducers';
import { ManageUserTableState } from './types';

const initialState: ManageUserTableState = {
  credentialedCollaborators: [],
  credentialedCollaboratorsCount: 0,
  getCredentialedCollaboratorsLoadingState: 'idle',
  patchCredentialedCollaboratorsLoadingState: 'idle',
  postCredentialedCollaboratorsLoadingState: 'idle',
  postCredentialedCollaboratorsErrorCode: 200,
  postCredentialedCollaboratorsLoadingStateMap: {},
  paginationModel: {
    page: 0,
    pageSize: 10,
  },
  showRoleChangeConfirmationModal: false,
  roleConfirmationData: {
    id: '',
    role: '',
  },
  showRevokeConfirmationModal: false,
  buttonActionDropdownById: {},
};

export const manageUsersTableSlice = createSlice({
  name: 'manageUsersActionsSlice',
  initialState,
  reducers: {
    resetState: () => initialState,
    setPaginationModel: setPaginationModelReducer,
    setRoleConfirmationData: setRoleConfirmationDataReducer,
    setShowRoleChangeConfirmationModal:
      setShowRoleChangeConfirmationModalReducer,
    setShowRevokeConfirmationModal: setShowRevokeConfirmationModalReducer,
    setCredentialedCollaboratorsLoadingState:
      setPostCredentialedCollaboratorsLoadingStateReducer,
    setButtonActionDropdownById: setButtonActionDropdownByIdReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCredentialedCollaborators.pending,
      getCredentialedCollaboratorsPendingReducer
    );
    builder.addCase(
      getCredentialedCollaborators.fulfilled,
      getCredentialedCollaboratorsFulfilledReducer
    );
    builder.addCase(
      getCredentialedCollaborators.rejected,
      getCredentialedCollaboratorsRejectedReducer
    );
    builder.addCase(
      patchCredentialedCollaborators.pending,
      patchCredentialedCollaboratorsPendingReducer
    );
    builder.addCase(
      patchCredentialedCollaborators.fulfilled,
      patchCredentialedCollaboratorsFulfilledReducer
    );
    builder.addCase(
      patchCredentialedCollaborators.rejected,
      patchCredentialedCollaboratorsRejectedReducer
    );
    builder.addCase(
      postCredentialedCollaborators.pending,
      postCredentialedCollaboratorsPendingReducer
    );
    builder.addCase(
      postCredentialedCollaborators.fulfilled,
      postCredentialedCollaboratorsFulfilledReducer
    );
    builder.addCase(
      postCredentialedCollaborators.rejected,
      postCredentialedCollaboratorsRejectedReducer
    );
  },
});

export const {
  resetState,
  setPaginationModel,
  setRoleConfirmationData,
  setShowRoleChangeConfirmationModal,
  setShowRevokeConfirmationModal,
  setCredentialedCollaboratorsLoadingState,
  setButtonActionDropdownById,
} = manageUsersTableSlice.actions;
