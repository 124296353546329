import { SxProps } from '@mui/material/styles';

export const DataGridSx: SxProps = {
  borderRadius: '10px',
  '& .MuiDataGrid-main': {
    borderRadius: '10px',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: 'rgba(250, 250, 250, 1)',
  },
  '& .Mui-checked': {
    color: 'colors.woodSmoke',
  },
  '& .MuiButtonBase-root': {
    color: 'colors.woodSmoke',
    '&:hover': {
      backgroundColor: 'colors.ghostWhite',
    },
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: 'colors.strongBlue',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
};
