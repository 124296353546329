/* eslint-disable sonarjs/cognitive-complexity */
import { useTheme } from '@mui/material';
import { AssetStatusSelfDescriptive } from 'global-constants';
import { transformAssetStatusDetailsText } from './utils';

export const useGetAssetStatusDetailsConfig = (
  assetStatus: AssetStatusSelfDescriptive
) => {
  const theme = useTheme();

  const transformedAssetStatus = transformAssetStatusDetailsText(assetStatus);

  switch (assetStatus) {
    case AssetStatusSelfDescriptive.MONO_ACTIVATION_LOCKED:
    case AssetStatusSelfDescriptive.MONO_IN_INVENTORY_DEVICE_ISSUE:
    case AssetStatusSelfDescriptive.MONO_MDM_LOCKED:
    case AssetStatusSelfDescriptive.ACTIVATION_LOCKED:
    case AssetStatusSelfDescriptive.IN_INVENTORY_DEVICE_ISSUE:
    case AssetStatusSelfDescriptive.MDM_LOCKED:
      return {
        text: transformedAssetStatus,
        color: theme.palette.colors.jaffa,
        textColor: theme.palette.colors.white,
      };
    case AssetStatusSelfDescriptive.MONO_ALLOCATED:
    case AssetStatusSelfDescriptive.MONO_DONATED:
    case AssetStatusSelfDescriptive.MONO_RECYCLED:
    case AssetStatusSelfDescriptive.MONO_SOLD:
    case AssetStatusSelfDescriptive.MONO_RECOVERY_LOCKED:
    case AssetStatusSelfDescriptive.RECOVERY_LOCKED:
    case AssetStatusSelfDescriptive.ALLOCATED:
    case AssetStatusSelfDescriptive.TRANSFERRED:
    case AssetStatusSelfDescriptive.MONO_TRANSFERRED:
    case AssetStatusSelfDescriptive.INTAKE:
    case AssetStatusSelfDescriptive.MONO_INTAKE:
    case AssetStatusSelfDescriptive.DONATED:
    case AssetStatusSelfDescriptive.RECYCLED:
    case AssetStatusSelfDescriptive.SOLD:
      return {
        text: transformedAssetStatus,
        color: theme.palette.colors.goldFusion,
        textColor: theme.palette.colors.white,
      };
    case AssetStatusSelfDescriptive.MONO_HOLD:
    case AssetStatusSelfDescriptive.HOLD:
      return {
        text: transformedAssetStatus,
        color: theme.palette.colors.grayE0,
        textColor: theme.palette.colors.black,
      };
    case AssetStatusSelfDescriptive.MONO_IN_INVENTORY:
    case AssetStatusSelfDescriptive.IN_INVENTORY:
      return {
        text: transformedAssetStatus,
        color: theme.palette.colors.maizeYO,
        textColor: theme.palette.colors.black,
      };
    case AssetStatusSelfDescriptive.MONO_LOST:
    case AssetStatusSelfDescriptive.LOST:
      return {
        text: transformedAssetStatus,
        color: theme.palette.colors.burntSiennaR,
        textColor: theme.palette.colors.white,
      };
    case AssetStatusSelfDescriptive.MONO_PROCESSING_DEVICE:
    case AssetStatusSelfDescriptive.MONO_QUOTE_IN_PROGRESS:
    case AssetStatusSelfDescriptive.MONO_PENDING_ALLOCATION:
    case AssetStatusSelfDescriptive.MONO_PENDING_CERTIFICATE:
    case AssetStatusSelfDescriptive.MONO_PENDING_QUOTE_APPROVAL:
    case AssetStatusSelfDescriptive.MONO_PENDING_RECYCLE:
    case AssetStatusSelfDescriptive.MONO_PENDING_REPAIR:
    case AssetStatusSelfDescriptive.MONO_PENDING_RETRIEVAL:
    case AssetStatusSelfDescriptive.MONO_PENDING_SALE:
    case AssetStatusSelfDescriptive.MONO_PENDING_WIPE:
    case AssetStatusSelfDescriptive.PROCESSING_DEVICE:
    case AssetStatusSelfDescriptive.QUOTE_IN_PROGRESS:
    case AssetStatusSelfDescriptive.PENDING_ALLOCATION:
    case AssetStatusSelfDescriptive.PENDING_CERTIFICATE:
    case AssetStatusSelfDescriptive.PENDING_QUOTE_APPROVAL:
    case AssetStatusSelfDescriptive.PENDING_RECYCLE:
    case AssetStatusSelfDescriptive.PENDING_REPAIR:
    case AssetStatusSelfDescriptive.PENDING_RETRIEVAL:
    case AssetStatusSelfDescriptive.PENDING_SALE:
    case AssetStatusSelfDescriptive.PENDING_WIPE:
      return {
        text: transformedAssetStatus,
        color: theme.palette.colors.pelorous,
        textColor: theme.palette.colors.white,
      };
    default:
      console.warn(`Unsupported Asset Status type: ${assetStatus}`);
      return {
        text: transformedAssetStatus,
        color: theme.palette.colors.maizeYO,
        textColor: theme.palette.colors.black,
      };
  }
};
