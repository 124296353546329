import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import NewModal from 'components/NewModal';

import {
  selectShowRoleChangeConfirmationModal,
  selectRoleConfirmationData,
  selectPatchCredentialedCollaboratorsLoadingState,
} from 'store/slices/manage_users/table/selectors';

import { useHandleRoleOnClose, useHandleOnSubmit } from './hooks';

import {
  FormChildrenBoxSx,
  OuterStackPropsSx,
  PaperPropsSx,
  BottomStackSx,
} from './styles';

import { CREDENTIALED_COLLABORATOR_ROLES } from 'globals/constants/credentialedCollaborators';

const { EXTERNAL_PRIMARY_ADMINISTRATOR, EXTERNAL_SECONDARY_ADMINISTRATOR } =
  CREDENTIALED_COLLABORATOR_ROLES;

const ChangeRoleConfirmationModal = () => {
  const open = useSelector(selectShowRoleChangeConfirmationModal);
  const patchCredentialedCollaboratorsLoadingState = useSelector(
    selectPatchCredentialedCollaboratorsLoadingState
  );
  const data = useSelector(selectRoleConfirmationData);
  const handleRoleOnClose = useHandleRoleOnClose();
  const handleOnSubmit = useHandleOnSubmit();
  let label = '';

  if (data.role === EXTERNAL_PRIMARY_ADMINISTRATOR) {
    label = 'Primary admin';
  }

  if (data.role === EXTERNAL_SECONDARY_ADMINISTRATOR) {
    label = 'Secondary admin';
  }

  const loading = patchCredentialedCollaboratorsLoadingState === 'pending';
  return (
    <Stack>
      <NewModal
        open={open}
        form
        dividerPlacement="none"
        title={`Are you sure you want to change the role to "${label}"?`}
        handleClose={handleRoleOnClose}
        loading={loading}
        outerStackPropsSx={OuterStackPropsSx}
        paperPropsSx={PaperPropsSx}
        formChildrenBoxSx={FormChildrenBoxSx}
        showCloseButtonTopRight={false}
        // @ts-ignore
        onSubmit={handleOnSubmit}
        submitButtonCopy="Yes, change"
        cancelButtonCopy="No, cancel"
        omitIndicator
        buttonStackSx={BottomStackSx}
      >
        This action is irreversible, the admin will be {label} and get all the
        corresponding rights.
      </NewModal>
    </Stack>
  );
};

export default ChangeRoleConfirmationModal;
