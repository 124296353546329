import {
  CredentialedCollaboratorRole,
  CollaboratorAuthenticationMode,
} from 'api/rest/credentialedCollaborators/types';

export const CREDENTIALED_COLLABORATOR_ROLES: Record<
  string,
  CredentialedCollaboratorRole
> = {
  EXTERNAL_PRIMARY_ADMINISTRATOR: 'EXTERNAL_PRIMARY_ADMINISTRATOR',
  EXTERNAL_SECONDARY_ADMINISTRATOR: 'EXTERNAL_SECONDARY_ADMINISTRATOR',
  INTERNAL_ACCOUNT_MANAGER: 'INTERNAL_ACCOUNT_MANAGER',
  INTERNAL_ADMINISTRATOR: 'INTERNAL_ADMINISTRATOR',
  INTERNAL_ASSET_MANAGER: 'INTERNAL_ASSET_MANAGER',
  INTERNAL_ENGINEER: 'INTERNAL_ENGINEER',
  INTERNAL_PRODUCT: 'INTERNAL_PRODUCT',
  INTERNAL_SALES_REPRESENTATIVE: 'INTERNAL_SALES_REPRESENTATIVE',
};

export const AUTHENTICATION_MODES: Record<
  string,
  CollaboratorAuthenticationMode
> = {
  AUTHENTICATION_MODE_SOCIAL: 'SOCIAL',
  AUTHENTICATION_MODE_PASSWORD: 'PASSWORD',
};
