import TooltipHeader from './TooltipHeader';
import { DataGridProProps } from '@mui/x-data-grid-pro';

import { HeaderType } from './types';

const headers: Record<
  HeaderType,
  DataGridProProps['columns'][0]['renderHeader']
> = {
  'tooltip-header': TooltipHeader,
};

export default headers;
