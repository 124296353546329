import { WritableDraft } from 'immer';
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  GlobalState,
  GlobalNotificationBanner,
  GlobalAvatarPopover,
} from './types';
import { setLocalStorageValue } from 'api/storage';
import { Collaborator } from 'types';
import { getCollaborator } from './actions';

export const setAuthTokenReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<string>
) => {
  const authToken = action.payload;
  state.authToken = authToken;
  setLocalStorageValue('authToken', authToken);
};

export const setAuthErrorCodeReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<number>
) => {
  state.authErrorCode = action.payload;
};

export const setGlobalNotificationBannerReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<GlobalNotificationBanner>
) => {
  state.globalNotificationBanner = action.payload;
};

export const setGlobalAvatarPopoverReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<GlobalAvatarPopover>
) => {
  state.globalAvatarPopover = action.payload;
};

export const setGlobalAuth0CollaboratorReducer = (
  state: WritableDraft<GlobalState>,
  action: PayloadAction<Collaborator>
) => {
  state.globalAuth0Collaborator = action.payload;
};

export const getCollaboratorPendingReducer: CaseReducer<
  GlobalState,
  ReturnType<typeof getCollaborator.pending>
> = (state, action) => {};

export const getCollaboratorFulfilledReducer: CaseReducer<
  GlobalState,
  ReturnType<typeof getCollaborator.fulfilled>
> = (state, action) => {
  state.globalAuth0Collaborator = action.payload;
};

export const getCollaboratorRejectedReducer: CaseReducer<
  GlobalState,
  ReturnType<typeof getCollaborator.rejected>
> = (state, action) => {};
