export const ErrorPageContainerSx = {
  width: '100vw',
  height: '100vh',
  backgroundColor: 'colors.antiqueW',
};

export const ErrorPageHeaderSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '57%',
};

export const ErrorPageContentSx = {
  pb: '20px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '37%',
  overflowY: 'auto',
  '& img': {
    height: '263px',
    width: '197px',
  },
};
export const ErrorPageFooterSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '6%',
  width: '100%',
};

export const ErrorPageInnerFooterSx = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '95%',
  height: '100%',
  borderTop: '1px solid rgba(19, 20, 20, 0.2)',
  fontSize: '13px',
  '& a': {
    textDecoration: 'none',
    color: 'black',
  },
};

export const ErrorPageHeaderLogoSx = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    width: '120px',
    height: '24px',
  },
};

export const ErrorPageHeaderTitleSx = {
  width: '100%',
  display: 'flex',
  fontSize: '44px',
  fontWeight: '500',
  justifyContent: 'center',
  alignItems: 'center',
  pt: '50px',
};

export const ErrorPageHeaderSubtitleSx = {
  pt: '10px',
  textAlign: 'center',
  width: '100%',
  display: 'flex',
  fontSize: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  '& a': {
    textDecoration: 'underline',
    color: 'black',
  },
};

export const ErrorPageHeaderInlineSubtitleSx = {
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ErrorPageHeaderButtonSx = {
  pt: '25px',
  width: '100%',
  display: 'flex',
  fontSize: '18px',
  justifyContent: 'center',
  alignItems: 'center',
};

export const TryAgainButtonSx = {
  width: '150px',
  height: '45px',
  color: 'white',
  backgroundColor: 'black',
  borderRadius: '25px',
  textTransform: 'none',
  '&:hover': {
    color: 'black',
    backgroundColor: 'white',
  },
};
