import React from 'react';
import Stack from '@mui/material/Stack';
import {
  ContainerSx,
  HeaderLeftSx,
  HeaderRightSx,
  HeaderSx,
  TableContentSx,
  CTAContentSx,
  TitleSx,
} from './styles';
import InviteAdminButton from './actions/InviteAdminButton';
import InviteAdminModal from './modals/InviteAdminModal';
import ChangeRoleConfirmationModal from './modals/ChangeRoleConfirmationModal';
import RevokeRoleConfirmationModal from './modals/RevokeRoleConfirmationModal';
import ManageUsersTable from './table/ManageUsersTable';
import { useShowNotifications, useResetStateOnUnmount } from './hooks';
import InviteAdminCTA from './actions/InviteAdminCTA';

const ManageUsers = () => {
  useShowNotifications();
  useResetStateOnUnmount();

  return (
    <Stack sx={ContainerSx}>
      <Stack sx={HeaderSx}>
        <Stack sx={HeaderLeftSx}>
          <Stack sx={TitleSx}>Manage users</Stack>
        </Stack>
        <Stack sx={HeaderRightSx}>
          <InviteAdminButton />
        </Stack>
      </Stack>
      <Stack sx={TableContentSx}>
        <ManageUsersTable />
      </Stack>
      <Stack sx={CTAContentSx}>
        <InviteAdminCTA />
      </Stack>
      <InviteAdminModal />
      <ChangeRoleConfirmationModal />
      <RevokeRoleConfirmationModal />
    </Stack>
  );
};

export default ManageUsers;
