import React from 'react';
import { SVGProps } from '../../types';

const TransferredIcon = (props: SVGProps) => {
  const originalSize = 24;
  const colorFill = props?.fill ?? 'white';
  const width = props?.width ?? originalSize;
  const height = props?.height ?? originalSize;
  const stroke = props?.stroke ?? 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${originalSize} ${originalSize}`}
      fill={colorFill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#4DB7C6" />
      <path d="M5.3335 18V6H12.0002V8.66667H18.6668V14H17.3335V10H12.0002V11.3333H13.3335V12.6667H12.0002V14H13.3335V15.3333H12.0002V16.6667H14.6668V18H5.3335ZM6.66683 16.6667H8.00016V15.3333H6.66683V16.6667ZM6.66683 14H8.00016V12.6667H6.66683V14ZM6.66683 11.3333H8.00016V10H6.66683V11.3333ZM6.66683 8.66667H8.00016V7.33333H6.66683V8.66667ZM9.3335 16.6667H10.6668V15.3333H9.3335V16.6667ZM9.3335 14H10.6668V12.6667H9.3335V14ZM9.3335 11.3333H10.6668V10H9.3335V11.3333ZM9.3335 8.66667H10.6668V7.33333H9.3335V8.66667ZM17.3335 19.3333V18H16.0002V16.6667H17.3335V15.3333H18.6668V16.6667H20.0002V18H18.6668V19.3333H17.3335ZM14.6668 12.6667V11.3333H16.0002V12.6667H14.6668ZM14.6668 15.3333V14H16.0002V15.3333H14.6668Z" fill="white"/>
    </svg>
  );
};

export default TransferredIcon;
