import useGetUserPermissions from 'hooks/useGetUserPermissions';
import { UserPermissions } from 'global-constants';

export const useCanCurrentUserToggleOrganizations = () => {
  const userPermissions = useGetUserPermissions();
  return userPermissions.includes(UserPermissions.ORGANIZATION__TOGGLE);
};

export const useCanReadCollaborators = () => {
  const userPermissions = useGetUserPermissions();
  return userPermissions.includes(UserPermissions.READ_COLLABORATORS);
};

export const useCanWriteCollaborators = () => {
  const userPermissions = useGetUserPermissions();
  return userPermissions.includes(UserPermissions.WRITE_COLLABORATORS);
};
