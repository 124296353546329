export const DATABASE_ASSET_STATUSES: { [index: string]: string } = {
  ACTIVATION_LOCKED: 'Activation_Locked',
  ALLOCATED: 'Allocated',
  DONATED: 'Donated',
  HOLD: 'HOLD',
  IN_INVENTORY: 'In_Inventory',
  IN_INVENTORY_DEVICE_ISSUE: 'In_inventory___device_issue',
  LOST: 'Lost',
  MDM_LOCKED: 'MDM_Locked',
  PENDING_ALLOCATION: 'Pending_Allocation',
  PENDING_CERTIFICATION: 'Pending_Certification',
  PENDING_QUOTE_APPROVAL: 'Pending_Quote_Approval',
  PENDING_RECYCLE: 'Pending_Recycle',
  PENDING_REPAIR: 'Pending_Repair',
  PENDING_RETRIEVAL: 'Pending_Retrieval',
  PENDING_SALE: 'Pending_Sale',
  PENDING_WIPE: 'Pending_Wipe',
  PROCESSING_DEVICE: 'Processing_Device',
  QUOTE_IN_PROGRESS: 'Quote_in_Progress',
  RECYCLED: 'Recycled',
  SOLD: 'Sold',
  RECOVERY_LOCKED: 'Recovery_Locked',
  TRANSFERRED: 'Transferred',
  INTAKE: 'Intake',
};

export const ASSET_STATUS_LABELS: {
  [index: string]: string;
} = {
  Activation_Locked: 'Activation locked',
  Allocated: 'Allocated',
  Donated: 'Donated',
  HOLD: 'HOLD',
  In_Inventory: 'In inventory',
  In_inventory___device_issue: 'In inventory - device issue',
  Lost: 'Lost',
  MDM_Locked: 'MDM locked',
  Pending_Allocation: 'Pending allocation',
  Pending_Certification: 'Pending certificate',
  Pending_Quote_Approval: 'Pending quote approval',
  Pending_Recycle: 'Pending recycle',
  Pending_Repair: 'Pending repair',
  Pending_Retrieval: 'Pending retrieval',
  Pending_Sale: 'Pending sale',
  Pending_Wipe: 'Pending wipe',
  Processing_Device: 'Processing device',
  Quote_in_Progress: 'Quote in progress',
  Recycled: 'Recycled',
  Sold: 'Sold',
  Recovery_Locked: 'Recovery locked',
  Transferred: 'Transferred',
  Intake: 'Intake',
};

export const COMPLEX_SEARCH_ASSET_STATUSES: { [index: string]: string } = {
  ACTIVATION_LOCKED: 'Activation Locked',
  RECOVERY_LOCKED: 'Recovery Locked',
  ALLOCATED: 'Allocated',
  DONATED: 'Donated',
  HOLD: 'HOLD',
  IN_INVENTORY: 'In Inventory',
  IN_INVENTORY_DEVICE_ISSUE: 'In inventory - device issue',
  LOST: 'Lost',
  MDM_LOCKED: 'MDM Locked',
  PENDING_ALLOCATION: 'Pending Allocation',
  PENDING_CERTIFICATE: 'Pending Certificate',
  PENDING_QUOTE_APPROVAL: 'Pending Quote Approval',
  PENDING_RECYCLE: 'Pending Recycle',
  PENDING_REPAIR: 'Pending Repair',
  PENDING_RETRIEVAL: 'Pending Retrieval',
  PENDING_SALE: 'Pending Sale',
  PENDING_WIPE: 'Pending Wipe',
  PROCESSING_DEVICE: 'Processing Device',
  QUOTE_IN_PROGRESS: 'Quote in Progress',
  RECYCLED: 'Recycled',
  SOLD: 'Sold',
  TRANSFERRED: 'Transferred',
  INTAKE: 'Intake',
};
