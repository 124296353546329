import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import { ButtonSx } from './styles';
import { ButtonActionCellProps } from './types';

const ButtonActionCell = (params: any) => {
  const { row, colDef } = params;
  const { field } = colDef;
  const props: ButtonActionCellProps = row[field];
  const { text, loading, disabled, hide, onButtonClick } = props;
  const handleOnClick = React.useCallback(() => {
    onButtonClick();
  }, []);
  if (hide) return null;
  return (
    <Stack>
      <LoadingButton
        sx={ButtonSx}
        onClick={handleOnClick}
        loading={loading}
        disabled={disabled}
      >
        {text}
      </LoadingButton>
    </Stack>
  );
};

export default ButtonActionCell;
