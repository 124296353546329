import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import authErrorManImage from 'assets/auth-error-man-image.png';
import allwhere from 'assets/allwhere.png';
import {
  EXPIRED_LINK_COPY,
  NOT_INVITED_TO_ORG_COPY,
  TERMS_AND_CONDITIONS_URL,
  GENERIC_ERROR_COPY,
} from './constants';

import { useHandleRedirect } from './hooks';

import { Auth0ErrorPageProps } from './types';

import {
  ErrorPageContainerSx,
  ErrorPageHeaderSx,
  ErrorPageContentSx,
  ErrorPageFooterSx,
  ErrorPageInnerFooterSx,
  ErrorPageHeaderLogoSx,
  ErrorPageHeaderTitleSx,
  ErrorPageHeaderSubtitleSx,
  ErrorPageHeaderInlineSubtitleSx,
  ErrorPageHeaderButtonSx,
  TryAgainButtonSx,
} from './styles';

const Auth0ErrorPage = (props: Auth0ErrorPageProps) => {
  const date = new Date();
  const year = date.getFullYear();
  const { type } = props;
  const handleRedirect = useHandleRedirect();

  return (
    <Stack sx={ErrorPageContainerSx}>
      <Stack sx={ErrorPageHeaderSx}>
        <Stack sx={ErrorPageHeaderLogoSx}>
          <img src={allwhere} alt="Allwhere" />
        </Stack>
        <Stack sx={ErrorPageHeaderTitleSx}>Oops! Login failed</Stack>
        <Stack sx={ErrorPageHeaderSubtitleSx}>
          <Stack sx={ErrorPageHeaderInlineSubtitleSx}>
            {type === 404 ? NOT_INVITED_TO_ORG_COPY : null}
            {type === 410 ? EXPIRED_LINK_COPY : null}
            {type === 500 ? GENERIC_ERROR_COPY : null}
          </Stack>
        </Stack>
        <Stack sx={ErrorPageHeaderButtonSx}>
          <Button variant="text" sx={TryAgainButtonSx} onClick={handleRedirect}>
            Try again
          </Button>
        </Stack>
      </Stack>
      <Stack sx={ErrorPageContentSx}>
        <img src={authErrorManImage} alt="Auth0" />
      </Stack>
      <Stack sx={ErrorPageFooterSx}>
        <Stack sx={ErrorPageInnerFooterSx}>
          &copy; Copyright {year}
          &nbsp; &nbsp; &nbsp;
          <a href={TERMS_AND_CONDITIONS_URL} target="_blank">
            Terms & Conditions
          </a>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Auth0ErrorPage;
