import * as Sentry from '@sentry/react';
import { ApolloError } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { GraphQLFormattedError } from 'graphql/error';

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENV,
  REACT_APP_SENTRY_RELEASE,
  REACT_APP_ENABLE_SENTRY_SESSION_REPLAY,
  REACT_APP_SENTRY_FEEDBACK,
} = process.env;

const onUnhandledError = (event: ErrorEvent) => {
  try {
    Sentry.captureException(event, (scope) => {
      scope.setContext('context', { event: JSON.stringify(event) });
      return scope;
    });
  } catch (e) {
    console.error('Could not send unhandled error to sentry');
  }
};

const onUnhandledException = (event: PromiseRejectionEvent) => {
  try {
    Sentry.captureException(event, (scope) => {
      scope.setContext('context', {
        event: JSON.stringify(event),
        reason: event.reason,
      });
      return scope;
    });
  } catch (e) {
    console.error('Could not send unhandled exception to sentry');
  }
};

export const captureSentryException = (
  errorResponse: ErrorResponse,
  error: GraphQLFormattedError,
  fingerprint: string[],
) => {
  const { operation } = errorResponse;
  if (!operation) return;
  Sentry.withScope((scope) => {
    scope.clear();
    scope.setFingerprint(fingerprint);
    scope.setTag('kind', operation.operationName);
    scope.setExtra('query', operation.query);
    scope.setExtra('variables', operation.variables);
    scope.setContext('context', {
      query: JSON.stringify(operation.query),
      operationName: operation.operationName,
      variables: JSON.stringify(operation.variables),
      extensions: JSON.stringify(operation.extensions),
    });
    if (error.path) {
      scope.addBreadcrumb({
        category: 'query-path',
        message: error.path.join(' > '),
        level: 'debug',
      });
    }
    const e = new Error(error.message);
    e.name = `Failed ${operation.operationName} - ${error.message}`;
    Sentry.captureException(e);
  });
};

const integrations = [
  Sentry.browserProfilingIntegration(),
  Sentry.browserTracingIntegration(),
  Sentry.extraErrorDataIntegration({ depth: 20 }),
];

// Enable sentry feedback button in the bottom right
if (REACT_APP_SENTRY_FEEDBACK === 'FEEDBACK_ON') {
  integrations.push(
    Sentry.feedbackIntegration({
      colorScheme: 'light',
      triggerLabel: '',
      showBranding: false,
      themeLight: {
        foreground: '#131414',
        outline: '#EEA600',
        accentForeground: '#131414',
        accentBackground: '#F9C54D',
        successColor: '#7A8450',
        errorColor: '#D94B36',
      },
      messagePlaceholder:
        'Please include as many details about the issues and what you expected to happen. We will do our best to fix this issue ASAP. Please refer to our release notes for updates.',
      successMessageText:
        'Confirmation: Bug submitted! For immediate help, contact hello@allwhere.co',
    }),
  );
}

if (JSON.parse(REACT_APP_ENABLE_SENTRY_SESSION_REPLAY || 'false')) {
  integrations.push(
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        process.env.REACT_APP_BFF_BASE_URL ||
          'https://store-service.allwhere.co/graphql',
      ],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  );
}

export const init = () => {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN || '',
    environment: REACT_APP_SENTRY_ENV || '',
    release: REACT_APP_SENTRY_RELEASE || '',
    integrations,
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/(app.|store.staging.|store.development.)?allwhere\.co/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
    maxValueLength: 16384,
  });
  // window.removeEventListener('error', onUnhandledError);
  // window.removeEventListener('unhandledrejection', onUnhandledException);
  window.onerror = (event, source, lineno, colno, error) => {
    if (error instanceof ApolloError) {
      for (const err of error.graphQLErrors) {
        if (err.extensions?.code === 'UNAUTHENTICATED') {
          return;
        }
      }
    }
    try {
      if (error) {
        const e = JSON.stringify(event);
        error.name = e;
        Sentry.captureException(error, (scope) => {
          scope.setFingerprint([
            e,
            source || 'N/A source',
            String(lineno) || 'N/A lineno',
            String(colno) || 'N/A colno',
            error?.message || 'N/A message',
          ]);
          scope.setContext('context', {
            event: e,
            source,
            lineno,
            colno,
          });
          return scope;
        });
      }
    } catch (e) {
      console.error('Could not send unhandled error to sentry');
    }
  };
  // window.addEventListener('error', onUnhandledError);
  // window.addEventListener('unhandledrejection', onUnhandledException);
};
