import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import CheckIcon from '@mui/icons-material/Check';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import {
  PRIMARY_ADMIN_COPY,
  SECONDARY_ADMIN_COPY,
} from '../../shared/constants';

import { InviteAdminModalFormProps } from './types';

import {
  TextFieldSx,
  FormSectionLabelSx,
  FormSectionLineSx,
  FormSectionLineContentLeftSx,
  FormSectionLineContentRightSx,
  AdminDescriptionSx,
  SelectSx,
  SelectedMenuItemSx,
  MenuItemContainerSx,
  MenuItemLeftSx,
  MenuItemRightSx,
  EmailErrorLeftSx,
} from './styles';

import {
  CREDENTIALED_COLLABORATOR_ROLES,
  AUTHENTICATION_MODES,
} from 'globals/constants/credentialedCollaborators';
import { handleOnBlurTrim } from './utils';
import {
  TEXT_VALIDATION_REGEX,
  EMAIL_FIELD_VALIDATION_REGEX,
} from './constants';

const { EXTERNAL_PRIMARY_ADMINISTRATOR, EXTERNAL_SECONDARY_ADMINISTRATOR } =
  CREDENTIALED_COLLABORATOR_ROLES;

const { AUTHENTICATION_MODE_PASSWORD, AUTHENTICATION_MODE_SOCIAL } =
  AUTHENTICATION_MODES;

const InviteAdminModalForm = (props: InviteAdminModalFormProps) => {
  const { form } = props;
  const { register, watch, formState } = form;
  const { errors } = formState;
  const selectedRole = watch('role');
  const selectedAuthenticationMode = watch('authenticationMode');
  return (
    <Stack>
      <Stack sx={FormSectionLabelSx}>Contact details</Stack>
      <Stack sx={FormSectionLineSx}>
        <Stack sx={FormSectionLineContentLeftSx}>
          <TextField
            sx={TextFieldSx}
            {...register('firstName', {
              required: true,
              pattern: {
                value: TEXT_VALIDATION_REGEX,
                message: 'Invalid email format',
              },
            })}
            onBlur={handleOnBlurTrim(form, 'firstName')}
            label="First name*"
            id="firstName*"
            aria-describedby="first name"
          />
        </Stack>
        <Stack sx={FormSectionLineContentRightSx}>
          <TextField
            sx={TextFieldSx}
            {...register('lastName', {
              required: true,
              pattern: {
                value: TEXT_VALIDATION_REGEX,
                message: 'Invalid email format',
              },
            })}
            onBlur={handleOnBlurTrim(form, 'lastName')}
            label="Last name*"
            id="lastName"
            aria-describedby="last name"
          />
        </Stack>
      </Stack>
      <Stack sx={FormSectionLineSx}>
        <Stack sx={FormSectionLineContentLeftSx}>
          <TextField
            sx={TextFieldSx}
            {...register('workEmail', {
              required: true,
              pattern: {
                value: EMAIL_FIELD_VALIDATION_REGEX,
                message: 'Invalid email format',
              },
            })}
            onBlur={handleOnBlurTrim(form, 'workEmail')}
            label="Work email*"
            id="workEmail"
            aria-describedby="workEmail"
          />
          {errors.workEmail && (
            <Stack sx={EmailErrorLeftSx}>{errors.workEmail.message}</Stack>
          )}
        </Stack>
      </Stack>
      <br />
      <Stack sx={FormSectionLabelSx}>Admin role</Stack>
      <Stack sx={FormSectionLineSx}>
        <Stack
          sx={{
            ...FormSectionLineContentLeftSx,
            justifyContent: 'center',
          }}
        >
          <FormControl>
            <InputLabel id="role-label">Select an option*</InputLabel>
            <Select
              labelId="role-label"
              sx={SelectSx}
              {...register('role', { required: true })}
              label="Select an option*"
              id="role"
              aria-describedby="role"
            >
              <MenuItem
                value={EXTERNAL_PRIMARY_ADMINISTRATOR as string}
                sx={
                  selectedRole === EXTERNAL_PRIMARY_ADMINISTRATOR
                    ? SelectedMenuItemSx
                    : {}
                }
              >
                <Stack sx={MenuItemContainerSx}>
                  <Stack sx={MenuItemLeftSx}>Primary admin</Stack>
                  {selectedRole === EXTERNAL_PRIMARY_ADMINISTRATOR ? (
                    <Stack sx={MenuItemRightSx}>
                      <CheckIcon fontSize="small" />
                    </Stack>
                  ) : null}
                </Stack>
              </MenuItem>
              <MenuItem
                value={EXTERNAL_SECONDARY_ADMINISTRATOR as string}
                // TODO: plug in once Redux state is in place
                sx={
                  selectedRole === EXTERNAL_SECONDARY_ADMINISTRATOR
                    ? SelectedMenuItemSx
                    : {}
                }
              >
                <Stack sx={MenuItemContainerSx}>
                  <Stack sx={MenuItemLeftSx}>Secondary admin</Stack>
                  {selectedRole === EXTERNAL_SECONDARY_ADMINISTRATOR ? (
                    <Stack sx={MenuItemRightSx}>
                      <CheckIcon fontSize="small" />
                    </Stack>
                  ) : null}
                </Stack>
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack
          sx={{
            ...FormSectionLineContentRightSx,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack sx={AdminDescriptionSx}>
            <span>{PRIMARY_ADMIN_COPY}</span>
            <span>{SECONDARY_ADMIN_COPY}</span>
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={FormSectionLabelSx}>Account setup method</Stack>
      <Stack sx={FormSectionLineSx}>
        <Stack
          sx={{
            ...FormSectionLineContentLeftSx,
            justifyContent: 'center',
          }}
        >
          <FormControl>
            <InputLabel id="authenticationMode-label">
              Select an option*
            </InputLabel>
            <Select
              labelId="authenticationMode-label"
              sx={SelectSx}
              {...register('authenticationMode', { required: true })}
              label="Select an option*"
              id="authenticationMode"
              aria-describedby="authenticationMode"
            >
              <MenuItem
                value={AUTHENTICATION_MODE_SOCIAL as string}
                sx={
                  selectedAuthenticationMode === AUTHENTICATION_MODE_SOCIAL
                    ? SelectedMenuItemSx
                    : {}
                }
              >
                <Stack sx={MenuItemContainerSx}>
                  <Stack sx={MenuItemLeftSx}>
                    Google/Microsoft (Social SSO)
                  </Stack>
                  {selectedAuthenticationMode === AUTHENTICATION_MODE_SOCIAL ? (
                    <Stack sx={MenuItemRightSx}>
                      <CheckIcon fontSize="small" />
                    </Stack>
                  ) : null}
                </Stack>
              </MenuItem>
              <MenuItem
                value={AUTHENTICATION_MODE_PASSWORD as string}
                sx={
                  selectedAuthenticationMode === AUTHENTICATION_MODE_PASSWORD
                    ? SelectedMenuItemSx
                    : {}
                }
              >
                <Stack sx={MenuItemContainerSx}>
                  <Stack sx={MenuItemLeftSx}>Set a Unique Password</Stack>
                  {selectedAuthenticationMode ===
                  AUTHENTICATION_MODE_PASSWORD ? (
                    <Stack sx={MenuItemRightSx}>
                      <CheckIcon fontSize="small" />
                    </Stack>
                  ) : null}
                </Stack>
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default InviteAdminModalForm;
