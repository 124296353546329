import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/immer';
import { ManageUserActionsState } from './types';

export const setManageUsersShowInviteAdminModalReducer = (
  state: WritableDraft<ManageUserActionsState>,
  action: PayloadAction<boolean>
) => {
  state.showInviteAdminModal = action.payload;
};
