import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import NewModal from 'components/NewModal';

import {
  selectPatchCredentialedCollaboratorsLoadingState,
  selectShowRevokeConfirmationModal,
} from 'store/slices/manage_users/table/selectors';

import { useHandleRoleRevokeOnClose, useHandleOnSubmit } from './hooks';

import {
  FormChildrenBoxSx,
  OuterStackPropsSx,
  PaperPropsSx,
  BottomStackSx,
} from './styles';

const RevokeRoleConfirmationModal = () => {
  const open = useSelector(selectShowRevokeConfirmationModal);
  const handleRoleOnClose = useHandleRoleRevokeOnClose();
  const handleOnSubmit = useHandleOnSubmit();
  const patchCredentialedCollaboratorsLoadingState = useSelector(
    selectPatchCredentialedCollaboratorsLoadingState
  );
  const loading = patchCredentialedCollaboratorsLoadingState === 'pending';
  return (
    <Stack>
      <NewModal
        open={open}
        form
        dividerPlacement="none"
        title="Are you sure you want to revoke access from the admin?"
        handleClose={handleRoleOnClose}
        loading={loading}
        outerStackPropsSx={OuterStackPropsSx}
        paperPropsSx={PaperPropsSx}
        formChildrenBoxSx={FormChildrenBoxSx}
        showCloseButtonTopRight={false}
        // @ts-ignore
        onSubmit={handleOnSubmit}
        submitButtonCopy="Yes, revoke"
        cancelButtonCopy="No, cancel"
        omitIndicator
        buttonStackSx={BottomStackSx}
      >
        This action is irreversible. The access will be revoked.
      </NewModal>
    </Stack>
  );
};

export default RevokeRoleConfirmationModal;
