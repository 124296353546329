import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowRevokeConfirmationModal } from 'store/slices/manage_users/table';
import { patchCredentialedCollaborators } from 'store/slices/manage_users/table/actions';
import { selectRoleConfirmationData } from 'store/slices/manage_users/table/selectors';
import { AppDispatch } from 'store';
import { CredentialedCollaboratorRole } from 'api/rest/credentialedCollaborators/types';

export const useHandleRoleRevokeOnClose = () => {
  const dispatch = useDispatch();
  return React.useCallback(() => {
    dispatch(setShowRevokeConfirmationModal(false));
  }, []);
};

export const useHandleOnSubmit = () => {
  const dispatch = useDispatch<AppDispatch>();
  const roleConfirmationData = useSelector(selectRoleConfirmationData);
  return React.useCallback(
    async (event: SubmitEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (roleConfirmationData?.id) {
        const { id, role }: { id: string; role: CredentialedCollaboratorRole } =
          roleConfirmationData;
        await dispatch(
          patchCredentialedCollaborators({
            collaboratorId: id,
            data: { role },
          })
        );
        dispatch(setShowRevokeConfirmationModal(false));
      }
    },
    [roleConfirmationData]
  );
};
