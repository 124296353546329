import * as MaterialIcons from '@mui/icons-material';
import { BackBoxSVGIcon, SmoothRecycleSVGIcon } from 'components/Icons';
import theme from 'themes/theme';
import { AssetStatusTransitionEnum } from 'pages/Assets/components/DevicesDatagrid/constants';

const ACTIVE_ICON_BLUE = theme.palette.colors.pelorous;
const ACTIVE_ICON_GREEN = theme.palette.colors.goldFusion;
const ACTIVE_ICON_ORANGE = theme.palette.colors.jaffa;
const ACTIVE_ICON_YELLOW = theme.palette.colors.maizeYO;
const ACTIVE_ICON_RED = theme.palette.colors.burntSiennaR;

export const INACTIVE_ICON_GRAY = theme.palette.colors.grayBD;

export const ASSET_STATUS_HISTORY_MAP = {
  [AssetStatusTransitionEnum.ACTIVATION_LOCKED]: {
    activeColor: ACTIVE_ICON_ORANGE,
    statusIcon: MaterialIcons.LockOutlined,
  },
  [AssetStatusTransitionEnum.ALLOCATED]: {
    activeColor: ACTIVE_ICON_GREEN,
    statusIcon: MaterialIcons.HomeOutlined,
  },
  [AssetStatusTransitionEnum.TRANSFERRED]: {
    activeColor: ACTIVE_ICON_GREEN,
    statusIcon: MaterialIcons.DomainAdd,
  },
  [AssetStatusTransitionEnum.INTAKE]: {
    activeColor: ACTIVE_ICON_GREEN,
    statusIcon: MaterialIcons.WarehouseOutlined,
  },
  [AssetStatusTransitionEnum.DONATED]: {
    activeColor: ACTIVE_ICON_GREEN,
    statusIcon: MaterialIcons.FavoriteBorderOutlined,
  },
  [AssetStatusTransitionEnum.HOLD]: {
    activeColor: INACTIVE_ICON_GRAY,
    statusIcon: MaterialIcons.PauseCircleOutlineOutlined,
  },
  [AssetStatusTransitionEnum.IN_INVENTORY]: {
    activeColor: ACTIVE_ICON_YELLOW,
    statusIcon: MaterialIcons.MapsHomeWorkOutlined,
  },
  [AssetStatusTransitionEnum.IN_INVENTORY_DEVICE_ISSUE]: {
    activeColor: ACTIVE_ICON_ORANGE,
    statusIcon: MaterialIcons.MapsHomeWorkOutlined,
  },
  [AssetStatusTransitionEnum.LOST]: {
    activeColor: ACTIVE_ICON_RED,
    statusIcon: MaterialIcons.LocationOffOutlined,
  },
  [AssetStatusTransitionEnum.MDM_LOCKED]: {
    activeColor: ACTIVE_ICON_ORANGE,
    statusIcon: MaterialIcons.LockOutlined,
  },
  [AssetStatusTransitionEnum.RECOVERY_LOCKED]: {
    activeColor: ACTIVE_ICON_ORANGE,
    statusIcon: MaterialIcons.LockOutlined,
  },
  [AssetStatusTransitionEnum.PENDING_ALLOCATION]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.AccessTimeOutlined,
  },
  [AssetStatusTransitionEnum.PENDING_CERTIFICATE]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.AccessTimeOutlined,
  },
  [AssetStatusTransitionEnum.PENDING_QUOTE_APPROVAL]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.AccessTimeOutlined,
  },
  [AssetStatusTransitionEnum.PENDING_RECYCLE]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: SmoothRecycleSVGIcon,
  },
  [AssetStatusTransitionEnum.PENDING_REPAIR]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.BuildOutlined,
  },
  [AssetStatusTransitionEnum.PENDING_RETRIEVAL]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: BackBoxSVGIcon,
  },
  [AssetStatusTransitionEnum.PENDING_SALE]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.AttachMoneyOutlined,
  },
  [AssetStatusTransitionEnum.PENDING_WIPE]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.CleaningServicesOutlined,
  },
  [AssetStatusTransitionEnum.PROCESSING_DEVICE]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.SettingsOutlined,
  },
  [AssetStatusTransitionEnum.QUOTE_IN_PROGRESS]: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.LaptopOutlined,
  },
  [AssetStatusTransitionEnum.RECYCLED]: {
    activeColor: ACTIVE_ICON_GREEN,
    statusIcon: SmoothRecycleSVGIcon,
  },
  [AssetStatusTransitionEnum.SOLD]: {
    activeColor: ACTIVE_ICON_GREEN,
    statusIcon: MaterialIcons.AttachMoneyOutlined,
  },
};

export const ASSET_STATUS_KEYWORD_HISTORY_MAP = {
  locked: {
    activeColor: ACTIVE_ICON_ORANGE,
    statusIcon: MaterialIcons.LockOutlined,
  },
  pending: {
    activeColor: ACTIVE_ICON_BLUE,
    statusIcon: MaterialIcons.AccessTimeOutlined,
  },
  other: {
    statusIcon: MaterialIcons.QuestionMarkOutlined,
    activeColor: INACTIVE_ICON_GRAY,
  },
};
