import { SxProps } from '@mui/material';

const PAPER_WIDTH = '600px';
const PAPER_HEIGHT = '300px';

export const OuterStackPropsSx = {
  height: '100%',
};

export const FormChildrenBoxSx: SxProps = {
  height: '100%',
  overflowY: 'scroll',
};

export const PaperPropsSx: SxProps = {
  maxWidth: PAPER_WIDTH,
  width: PAPER_WIDTH,
  height: PAPER_HEIGHT,
  overflow: 'hidden !important',
  display: 'flex',
};

export const BottomStackSx: SxProps = {
  height: '100px',
  display: 'flex',
  alignItems: 'center',
};
