import { createSlice } from '@reduxjs/toolkit';

import { setManageUsersShowInviteAdminModalReducer } from './reducers';
import { ManageUserActionsState } from './types';

const initialState: ManageUserActionsState = {
  showInviteAdminModal: false,
};

export const manageUsersActionsSlice = createSlice({
  name: 'manageUsersActionsSlice',
  initialState,
  reducers: {
    setManageUsersShowInviteAdminModalReducer,
    resetState: () => initialState,
  },
});

export const {
  resetState,
  setManageUsersShowInviteAdminModalReducer: setManageUsersShowInviteAdminModal,
} = manageUsersActionsSlice.actions;
