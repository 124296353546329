import Table from 'components/table/Table';
import Stack from '@mui/material/Stack';
import { ContainerSx } from './styles';
import {
  useGetUsersTableConfig,
  userGetCredentialedCollaborators,
  userGetInitialCredentialedCollaborators,
} from './hooks';

const ManageUsersTable = () => {
  userGetInitialCredentialedCollaborators();
  userGetCredentialedCollaborators();
  const config = useGetUsersTableConfig();
  return (
    <Stack sx={ContainerSx}>
      <Table {...config} />
    </Stack>
  );
};

export default ManageUsersTable;
