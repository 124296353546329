import React from 'react';
import { useSelector } from 'react-redux';

import IconButton from '@mui/material/IconButton';

import Stack from '@mui/material/Stack';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import assignAdminsCTAImage from 'assets/assign-admins-cta-image.png';

import { useHandleOnInviteAdminOpenClick } from '../InviteAdminButton/hooks';
import {
  selectCredentialedCollaboratorsPaginationModel,
  selectGetCredentialedCollaboratorsLoadingState,
  selectManageUsersCredentialedCollaborators,
} from 'store/slices/manage_users/table/selectors';

import {
  useCanCurrentUserToggleOrganizations,
  useCanWriteCollaborators,
} from '../../shared/hooks';

import {
  ContainerSx,
  CTAContentSx,
  CTAHeaderSx,
  CTASubHeaderSx,
  CTAIconButtonContainerSx,
  CTAIconButtonSx,
} from './styles';

const InviteAdminButton = () => {
  const handleOnInviteAdminOpenClick = useHandleOnInviteAdminOpenClick();
  const collaborators = useSelector(selectManageUsersCredentialedCollaborators);
  const getCredentialedCollaboratorsLoadingState = useSelector(
    selectGetCredentialedCollaboratorsLoadingState
  );
  const paginationModel = useSelector(
    selectCredentialedCollaboratorsPaginationModel
  );
  const canToggleOrganizations = useCanCurrentUserToggleOrganizations();
  const canWriteCollaborators = useCanWriteCollaborators();
  const actionsEnabled =
    canToggleOrganizations ||
    (!canToggleOrganizations && canWriteCollaborators);

  const showCTA =
    getCredentialedCollaboratorsLoadingState !== 'pending' &&
    getCredentialedCollaboratorsLoadingState !== 'idle' &&
    collaborators.length <= 1 &&
    paginationModel.page === 0;
  if (showCTA) {
    return (
      <Stack sx={ContainerSx}>
        <Stack sx={CTAContentSx}>
          <img src={assignAdminsCTAImage} />
        </Stack>
        <Stack sx={CTAHeaderSx}>Manage smarter, invite more admins</Stack>
        <Stack sx={CTASubHeaderSx}>
          Invite your team members to manage your work with allwhere. Assign
          roles, define permissions, and update access at any time.
        </Stack>
        <Stack sx={CTAIconButtonContainerSx}>
          <IconButton
            size="small"
            onClick={handleOnInviteAdminOpenClick}
            color="inherit"
            sx={CTAIconButtonSx}
            disabled={!actionsEnabled}
          >
            <PersonAddAlt1Icon fontSize="small" />
            &nbsp; Invite admin
          </IconButton>
        </Stack>
      </Stack>
    );
  }
  return null;
};

export default InviteAdminButton;
