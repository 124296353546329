import moment from 'moment/moment';

import PendingReturnIcon from 'components/Icons/homepage/orders/PendingReturnIcon';
import PendingRepairIcon from 'components/Icons/homepage/orders/PendingRepairIcon';
import QuoteInProgressIcon from 'components/Icons/homepage/orders/QuoteInProgressIcon';
import PendingRecycleIcon from 'components/Icons/homepage/orders/PendingRecycleIcon';
import PendingQuoteApprovalIcon from 'components/Icons/homepage/orders/PendingQuoteApprovalIcon';
import PendingAllocationIcon from 'components/Icons/homepage/assets/PendingAllocationIcon';
import AllocatedIcon from 'components/Icons/homepage/assets/AllocatedIcon';
import TransferredIcon from 'components/Icons/homepage/assets/TransferredIcon';
import IntakeIcon from 'components/Icons/homepage/assets/IntakeIcon';
import ProcessingDeviceIcon from 'components/Icons/homepage/orders/ProcessingDeviceIcon';
import RecycledIcon from 'components/Icons/homepage/assets/RecycledIcon';
import SoldIcon from 'components/Icons/homepage/assets/SoldIcon';
import DonatedIcon from 'components/Icons/homepage/assets/DonatedIcon';
import LostIcon from 'components/Icons/homepage/assets/LostIcon';
import PendingSaleIcon from 'components/Icons/homepage/assets/PendingSaleIcon';
import PendingWipeIcon from 'components/Icons/homepage/assets/PendingWipeIcon';
import ActivationLockedIcon from 'components/Icons/homepage/orders/ActivationLockedIcon';
import HoldIcon from 'components/Icons/homepage/assets/HoldIcon';
import DeviceIssueIcon from 'components/Icons/homepage/assets/DeviceIssueIcon';
import CompleteIcon from 'components/Icons/homepage/orders/CompleteIcon';

import { DATABASE_ASSET_STATUSES } from 'globals/constants/assets';
import { SVGProps } from 'components/Icons/types';

export const ICONS: { [index: string]: (props: SVGProps) => JSX.Element } = {
  [DATABASE_ASSET_STATUSES.IN_INVENTORY]: CompleteIcon,
  [DATABASE_ASSET_STATUSES.ALLOCATED]: AllocatedIcon,
  [DATABASE_ASSET_STATUSES.TRANSFERRED]: TransferredIcon,
  [DATABASE_ASSET_STATUSES.INTAKE]: IntakeIcon,
  [DATABASE_ASSET_STATUSES.RECYCLED]: RecycledIcon,
  [DATABASE_ASSET_STATUSES.SOLD]: SoldIcon,
  [DATABASE_ASSET_STATUSES.DONATED]: DonatedIcon,
  [DATABASE_ASSET_STATUSES.LOST]: LostIcon,
  [DATABASE_ASSET_STATUSES.PENDING_ALLOCATION]: PendingAllocationIcon,
  [DATABASE_ASSET_STATUSES.PENDING_CERTIFICATION]: PendingAllocationIcon,
  [DATABASE_ASSET_STATUSES.PENDING_RECYCLE]: PendingRecycleIcon,
  [DATABASE_ASSET_STATUSES.PENDING_REPAIR]: PendingRepairIcon,
  [DATABASE_ASSET_STATUSES.PENDING_SALE]: PendingSaleIcon,
  [DATABASE_ASSET_STATUSES.PROCESSING_DEVICE]: ProcessingDeviceIcon,
  [DATABASE_ASSET_STATUSES.QUOTE_IN_PROGRESS]: QuoteInProgressIcon,
  [DATABASE_ASSET_STATUSES.PENDING_RETRIEVAL]: PendingReturnIcon,
  [DATABASE_ASSET_STATUSES.PENDING_WIPE]: PendingWipeIcon,
  [DATABASE_ASSET_STATUSES.ACTIVATION_LOCKED]: ActivationLockedIcon,
  [DATABASE_ASSET_STATUSES.RECOVERY_LOCKED]: ActivationLockedIcon,
  [DATABASE_ASSET_STATUSES.HOLD]: HoldIcon,
  [DATABASE_ASSET_STATUSES.MDM_LOCKED]: ActivationLockedIcon,
  [DATABASE_ASSET_STATUSES.IN_INVENTORY_DEVICE_ISSUE]: DeviceIssueIcon,
  [DATABASE_ASSET_STATUSES.PENDING_QUOTE_APPROVAL]: PendingQuoteApprovalIcon,
};

export const ASSETS_LOOK_BACK_DATE = moment(Date.now())
  .subtract(14, 'd')
  .utc()
  .format();
