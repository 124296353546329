import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getCollaborator } from 'store/slices/global/actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';

export const useSetGlobalAuth0Collaborator = () => {
  const { user } = useAuth0();
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    if (user?.collaboratorId) {
      dispatch(getCollaborator({ collaboratorId: user?.collaboratorId }));
    }
  }, [user]);
};

export default useSetGlobalAuth0Collaborator;
