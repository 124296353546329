import capitalize from 'lodash/capitalize';
import {
  CollaboratorAuthenticationMode,
  CredentialStatus,
} from 'api/rest/credentialedCollaborators/types';

export const getAuthenticationModelLabel = (
  modes: CollaboratorAuthenticationMode[]
) => {
  if (modes.length === 0) {
    return 'N/A';
  }
  return modes.map((mode) => {
    return capitalize(mode);
  });
};

export const getCredentialStatusLabel = (status: CredentialStatus) => {
  const map: Record<CredentialStatus, string> = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    PENDING: 'Pending',
    EXPIRED: 'Invite expired',
  };
  return map[status];
};
