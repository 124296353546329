import React from 'react';

export const TERMS_AND_CONDITIONS_URL = 'https://www.allwhere.co/terms';
export const NOT_INVITED_TO_ORG_COPY = (
  <p>
    It looks like you're trying to log in using a social account, but you need
    an invitation from an administrator before accessing allwhere. Please
    contact your company's admin or reach out to our support team at{' '}
    <a href="mailto:hello@allwhere.co">hello@allwhere.co</a> for assistance. If
    you believe you should have access, please check with your admin to confirm
    your invitation status.
    <br />
    <br />
    Want to create an account for your organization? Reach out to our sales team
    at <a href="mailto:sales@allwhere.co">sales@allwhere.co</a>, and we'll be
    happy to help!
  </p>
);

export const EXPIRED_LINK_COPY = (
  <p>
    Your link has expired.
    <br />
    Please reach out to the administrator of your account for a new link.
  </p>
);

export const GENERIC_ERROR_COPY = (
  <p>Something went wrong. Please try again.</p>
);
