import React from 'react';
import { Navigator } from 'context/NavigationContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalAvatarPopover } from 'store/slices/global/selectors';
import {
  setAuthToken,
  setGlobalAvatarPopover,
} from 'store/slices/global/index';
import { GAVITTI_DIRECT_LINK } from './constants';

export const useHandleOnClickToggle = () => {
  const dispatch = useDispatch();
  const globalAvatarPopover = useSelector(selectGlobalAvatarPopover);
  return React.useCallback(() => {
    dispatch(
      setGlobalAvatarPopover({
        show: !globalAvatarPopover.show,
      })
    );
  }, [globalAvatarPopover.show]);
};

export const useHandlePopoverOnClose = () => {
  const dispatch = useDispatch();
  const globalAvatarPopover = useSelector(selectGlobalAvatarPopover);
  return React.useCallback(() => {
    dispatch(
      setGlobalAvatarPopover({
        show: false,
      })
    );
  }, [globalAvatarPopover.show]);
};

export const useHandleGotoBilling = (navigator: Navigator) => {
  return React.useCallback(() => {
    window.open(GAVITTI_DIRECT_LINK);
  }, []);
};

export const useHandleGotoManageUsers = (navigator: Navigator) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return React.useCallback(() => {
    navigate(navigator.pathToManageUsers());
    dispatch(setGlobalAvatarPopover({ show: false }));
  }, []);
};

export const useHandleLogout = () => {
  const dispatch = useDispatch();
  return React.useCallback(() => {
    dispatch(setAuthToken('logged-out'));
    dispatch(setGlobalAvatarPopover({ show: false }));
  }, []);
};
