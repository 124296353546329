import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setAuthErrorCode, setAuthToken } from 'store/slices';
import { setLocalStorageValue } from 'api/storage';

export const useHandleRedirect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return React.useCallback(() => {
    dispatch(setAuthErrorCode(0));
    dispatch(setAuthToken('logged-out'));
    setLocalStorageValue('authToken', 'logged-out');
    navigate('/');
  }, []);
};
