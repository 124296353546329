import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';

import {
  DEFAULT_GRID_FILTER_MODE,
  DEFAULT_HEADER_HEIGHT,
  DEFAULT_ROW_HEIGHT,
} from './constants';
import { CustomDataGridProProps } from './types';
import { configureColumns } from './utils';
import { DataGridSx } from './styles';

const Table = (props: CustomDataGridProProps) => {
  const columns = configureColumns(props.columns);

  return (
    <DataGridPro
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnReorder
      paginationMode="server"
      {...props}
      columns={columns}
      autoHeight={props.autoHeight ?? true}
      filterMode={props.filterMode || DEFAULT_GRID_FILTER_MODE}
      columnHeaderHeight={DEFAULT_HEADER_HEIGHT}
      rowHeight={DEFAULT_ROW_HEIGHT}
      sx={DataGridSx}
    />
  );
};

export default Table;
