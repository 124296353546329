import { TextCellProps } from './types';
import Stack from '@mui/material/Stack';
import { ContainerSx } from './styles';

const TextCell = (params: any) => {
  const { row, colDef } = params;
  const { field } = colDef;
  const props: TextCellProps = row[field];
  const { text, color = 'black' } = props;
  if (typeof text === 'object' && text.length > 1) {
    return <Stack sx={{ ...ContainerSx, color }}>{text.join(', ')}</Stack>;
  }
  if (typeof text === 'object' && text.length === 1) {
    return <Stack sx={{ ...ContainerSx, color }}>{text.join('')}</Stack>;
  }
  return <Stack sx={{ ...ContainerSx, color }}>{text}</Stack>;
};

export default TextCell;
