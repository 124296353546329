import validator from 'validator';
import Stack from '@mui/material/Stack';
import { EmailAddressCellProps } from './types';
import { ContainerSx } from './styles';

const EmailAddressCell = (params: any) => {
  const { row, colDef } = params;
  const { field } = colDef;
  const props: EmailAddressCellProps = row[field];
  const { email } = props;

  if (validator.isEmail(email)) {
    return <Stack sx={ContainerSx}>{email}</Stack>;
  }

  return <Stack sx={ContainerSx}>(invalid email)</Stack>;
};

export default EmailAddressCell;
