import React from 'react';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import { useHandleOnInviteAdminOpenClick } from './hooks';
import { IconButtonSx } from './styles';
import {
  useCanCurrentUserToggleOrganizations,
  useCanWriteCollaborators,
} from '../../shared/hooks';

const InviteAdminButton = () => {
  const handleOnInviteAdminOpenClick = useHandleOnInviteAdminOpenClick();
  const canToggleOrganizations = useCanCurrentUserToggleOrganizations();
  const canWriteCollaborators = useCanWriteCollaborators();
  const actionsEnabled =
    canToggleOrganizations ||
    (!canToggleOrganizations && canWriteCollaborators);

  const disabled = !actionsEnabled;
  return (
    <Stack>
      <IconButton
        disabled={disabled}
        sx={IconButtonSx}
        size="small"
        onClick={handleOnInviteAdminOpenClick}
      >
        <AddCircleOutlineOutlinedIcon fontSize="small" />
        &nbsp; Invite admin
      </IconButton>
    </Stack>
  );
};

export default InviteAdminButton;
