import { SxProps } from '@mui/material';

export const TextFieldSx: SxProps = {
  backgroundColor: 'transparent',
  p: 0,
  '&: .MuiFormHelperText-root': {
    color: 'transparent',
    position: 'absolute',
    bottom: '-20px',
  },
  width: '97%',
};

export const SelectSx: SxProps = {
  p: 0,
  width: '97%',
  '& svg': {
    visibility: 'hidden',
  },
};
export const SelectedMenuItemSx: SxProps = {
  backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
};

export const FormSectionLabelSx: SxProps = {
  color: 'rgba(19, 20, 20, 1)',
  fontWeight: '500',
  fontSize: '14px',
  mb: '5px',
};

export const FormSectionLineContentLeftSx = {
  display: 'flex',
  width: '50%',
};

export const FormSectionLineContentRightSx = {
  display: 'flex',
  width: '50%',
  alignItems: 'flex-end',
};

export const FormSectionLineSx = {
  display: 'flex',
  flexDirection: 'row',
  mb: '5px',
  mt: '5px',
  '& .Mui-focused': {
    color: 'black !important',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '1px solid rgba(19, 20, 20, 1)', // - Set the Input border when parent has :hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black !important',
      color: 'black !important',
    },
  },
};

export const AdminDescriptionSx = {
  display: 'flex',
  width: '100%',
  backgroundColor: 'rgba(250, 250, 250, 1)',
  padding: '12px',
  borderRadius: '10px',
  '& b': {
    color: 'rgba(19, 20, 20, 1) !important',
    fontSize: '14px',
  },
};

export const MenuItemContainerSx = {
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
};

export const MenuItemLeftSx = {
  display: 'flex',
  width: '50%',
};

export const MenuItemRightSx = {
  display: 'flex',
  width: '50%',
  alignItems: 'flex-end',
};

export const EmailErrorLeftSx = {
  color: 'red',
  fontSize: '12px',
  marginTop: '5px',
  width: '100%',
};

export const EmailErrorRightSx = {
  color: 'red',
  fontSize: '12px',
  marginTop: '5px',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  pl: '12px',
};
