import { AssetConditionEnum, AssetStatusEnum } from 'global-constants';
import { AssetDataStatusEnum, AssetColorsEnum, AssetTypeEnum } from './enums';

//Trick MUI Datagrid that there are a lot of rows to enable pagination
export const FAKE_ROW_COUNT = 1000000;

export const ASSET_ID = 'Asset ID';
export const ASSET_NUMBER = 'Asset Number';

/**
 * @summary This is an INCOMPLETE list of headers for downloading the Assets CSV file.
 * This list is used in a utils function that completes the list.
 */
export const HEADLESS_ASSET_CSV_HEADERS = [
  'Type',
  'Make',
  'Model',
  'Status',
  'Device issue',
  'Assignee',
  'Assignee email',
  'Condition',
  'Serial number',
  'Color',
  'Display size',
  'Memory',
  'Storage',
  'Processor',
  'Keyboard Configuration',
  'Depot Location',
  'Purchase Date',
  'Warranty Expiration Date',
  'Last allwhere order item number',
  'Date of last check-in',
  'Date of last check-out',
];

export const AssetDataStatusMapping = {
  WIPED: AssetDataStatusEnum.WIPED,
  NOT_WIPED: AssetDataStatusEnum.NOT_WIPED,
  NOT_APPLICABLE: AssetDataStatusEnum.NOT_APPLICABLE,
};

export const AssetColorsMapping = {
  ABYSS_BLUE: AssetColorsEnum.ABYSS_BLUE,
  GOLD: AssetColorsEnum.GOLD,
  GRAPHITE: AssetColorsEnum.GRAPHITE,
  GRAY: AssetColorsEnum.GRAY,
  MIDNIGHT_BLUE: AssetColorsEnum.MIDNIGHT_BLUE,
  PLATINUM: AssetColorsEnum.PLATINUM,
  RED: AssetColorsEnum.RED,
  ROSE_GOLD: AssetColorsEnum.ROSE_GOLD,
  SILVER: AssetColorsEnum.SILVER,
  SPACE_GRAY: AssetColorsEnum.SPACE_GRAY,
  STEEL_GRAY: AssetColorsEnum.STEEL_GRAY,
  STORM_GREY: AssetColorsEnum.STORM_GREY,
  WHITE: AssetColorsEnum.WHITE,
};

export const AssetConditionMapping = {
  NEW: AssetConditionEnum.S_NEW,
  A_LIKE_NEW: AssetConditionEnum.A_LIKE_NEW,
  B_VERY_GOOD: AssetConditionEnum.B_VERY_GOOD,
  C_GOOD: AssetConditionEnum.C_GOOD,
  D_FAIR: AssetConditionEnum.D_FAIR,
};

export const AssetTypeMapping = {
  ACCESSORY: AssetTypeEnum.ACCESSORY,
  ALL_IN_ONE_VC_BAR: AssetTypeEnum.ALL_IN_ONE_VC_BAR,
  CELL_PHONE: AssetTypeEnum.CELL_PHONE,
  CHARGER: AssetTypeEnum.CHARGER,
  CORD: AssetTypeEnum.CORD,
  CREDIT_CARD: AssetTypeEnum.CREDIT_CARD,
  DESKTOP: AssetTypeEnum.DESKTOP,
  HOTSPOT: AssetTypeEnum.HOTSPOT,
  KEYS: AssetTypeEnum.KEYS,
  KEY_FOB: AssetTypeEnum.KEY_FOB,
  LAPTOP: AssetTypeEnum.LAPTOP,
  MOBILE: AssetTypeEnum.MOBILE,
  MONITOR: AssetTypeEnum.MONITOR,
  OCULUS: AssetTypeEnum.OCULUS,
  PERIPHERAL: AssetTypeEnum.PERIPHERAL,
  PRINTER: AssetTypeEnum.PRINTER,
  SWAG: AssetTypeEnum.SWAG,
  SWAG_BOX: AssetTypeEnum.SWAG_BOX,
  SWAG_KIT_L: AssetTypeEnum.SWAG_KIT_L,
  SWAG_KIT_M: AssetTypeEnum.SWAG_KIT_M,
  SWAG_KIT_S: AssetTypeEnum.SWAG_KIT_S,
  SWAG_KIT_XL: AssetTypeEnum.SWAG_KIT_XL,
  SWAG_KIT_XS: AssetTypeEnum.SWAG_KIT_XS,
  SWAG_KIT_XXL: AssetTypeEnum.SWAG_KIT_XXL,
  SWAG_KIT_XXXL: AssetTypeEnum.SWAG_KIT_XXXL,
  TABLET: AssetTypeEnum.TABLET,
  WEBCAM: AssetTypeEnum.WEBCAM,
};

export const AssetStatusMapping = {
  ACTIVATION_LOCKED: AssetStatusEnum.ACTIVATION_LOCKED,
  ALLOCATED: AssetStatusEnum.ALLOCATED,
  DONATED: AssetStatusEnum.DONATED,
  HOLD: AssetStatusEnum.HOLD,
  IN_INVENTORY: AssetStatusEnum.IN_INVENTORY,
  IN_INVENTORY_DEVICE_ISSUE: AssetStatusEnum.IN_INVENTORY_DEVICE_ISSUE,
  LOST: AssetStatusEnum.LOST,
  MDM_LOCKED: AssetStatusEnum.MDM_LOCKED,
  TRANSFERRED: AssetStatusEnum.TRANSFERRED,
  INTAKE: AssetStatusEnum.INTAKE,
  PENDING_ALLOCATION: AssetStatusEnum.PENDING_ALLOCATION,
  PENDING_CERTIFICATE: AssetStatusEnum.PENDING_CERTIFICATE,
  PENDING_QUOTE_APPROVAL: AssetStatusEnum.PENDING_QUOTE_APPROVAL,
  PENDING_RECYCLE: AssetStatusEnum.PENDING_RECYCLE,
  PENDING_REPAIR: AssetStatusEnum.PENDING_REPAIR,
  PENDING_RETRIEVAL: AssetStatusEnum.PENDING_RETRIEVAL,
  PENDING_SALE: AssetStatusEnum.PENDING_SALE,
  PENDING_WIPE: AssetStatusEnum.PENDING_WIPE,
  PROCESSING_DEVICE: AssetStatusEnum.PROCESSING_DEVICE,
  RECOVERY_LOCKED: AssetStatusEnum.RECOVERY_LOCKED,
  QUOTE_IN_PROGRESS: AssetStatusEnum.QUOTE_IN_PROGRESS,
  RECYCLED: AssetStatusEnum.RECYCLED,
  SOLD: AssetStatusEnum.SOLD,
};

export const READ_ONLY_ASSET_TRANSITION_STATUSES = [
  ...Object.keys(AssetStatusEnum),
] as const;
