import { MonoService } from 'api/rest/config';
import {
  CredentialedCollaboratorBody,
  CredentialedCollaboratorResponse,
} from './types';
import { AxiosResponse } from 'axios';

export const getCredentialedCollaborators = async (args: {
  orgId: string;
  offset: number;
  limit: number;
}) => {
  const params = {
    organizationId: args.orgId,
    offset: args.offset,
    limit: args.limit,
  };
  return MonoService.get<CredentialedCollaboratorResponse, AxiosResponse>(
    `/v1/credentialed-collaborator/list`,
    { params }
  ).then((response) => response.data);
};

export const patchCredentialedCollaborators = async (args: {
  collaboratorId: string;
  data: Partial<CredentialedCollaboratorBody>;
}) => {
  const { collaboratorId, data } = args;
  return MonoService.patch<CredentialedCollaboratorResponse, AxiosResponse>(
    `/v1/credentialed-collaborator/${collaboratorId}`,
    data
  ).then((response) => response.data);
};

export const postCredentialedCollaborators = async (args: {
  data: CredentialedCollaboratorBody;
}) => {
  const { data } = args;
  return MonoService.post<CredentialedCollaboratorResponse, AxiosResponse>(
    `/v1/credentialed-collaborator`,
    data
  ).then((response) => response.data);
};
