import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import Stack from '@mui/material/Stack';
import NewModal from 'components/NewModal';
import InviteAdminModalForm from 'pages/ManageUsers/forms/InviteAdminModalForm';

import { selectManageUsersShowInviteAdminModal } from 'store/slices/manage_users/actions/selectors';
import { useHandleOnInviteAdminCloseClick } from '../../actions/InviteAdminButton/hooks';
import { selectPostCredentialedCollaboratorsLoadingState } from 'store/slices/manage_users/table/selectors';
import { useResetFormOnClose, useHandleOnSubmit } from './hooks';
import { PaperSx } from './styles';
import { InviteAdminFormInput } from '../../forms/InviteAdminModalForm/types';

const InviteAdminModal = () => {
  const open = useSelector(selectManageUsersShowInviteAdminModal);
  const handleOnInviteAdminCloseClick = useHandleOnInviteAdminCloseClick();
  const postCredentialedCollaboratorsLoadingState = useSelector(
    selectPostCredentialedCollaboratorsLoadingState
  );
  const loading = postCredentialedCollaboratorsLoadingState === 'pending';
  const form = useForm<InviteAdminFormInput>({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      workEmail: '',
      role: '',
      authenticationMode: '',
    },
  });

  useResetFormOnClose(form);

  const { formState } = form;

  const handleOnSubmit = useHandleOnSubmit(form);

  const disabled = formState.isValid;

  return (
    <Stack>
      <NewModal
        form
        title="Invite admin"
        open={open}
        dividerPlacement="both"
        handleClose={handleOnInviteAdminCloseClick}
        loading={loading}
        onSubmit={handleOnSubmit}
        submitButtonCopy="Send invite"
        cancelButtonCopy="Cancel"
        omitIndicator
        disableSaveButton={!disabled}
        paperPropsSx={PaperSx}
      >
        <InviteAdminModalForm form={form} />
      </NewModal>
    </Stack>
  );
};

export default InviteAdminModal;
