import theme from 'themes/theme';

export const ButtonSx = {
  border: '1px solid rgba(19, 20, 20, 0.5)',
  borderRadius: '30px',
  height: '36px',
  fontWeight: '600',
  fontSize: '14px',
  pl: '10px',
  pr: '10px',
};

export const SelectSx = {
  '& .MuiOutlinedInput-input': {
    pr: '0 !important',
  },
  '& .MuiSelect-outlined': {
    pointerEvents: 'none', // Disable pointer events on the main Select box
    border: '1px solid rgba(19, 20, 20, 0.5)',
    borderTopLeftRadius: '30px',
    borderBottomLeftRadius: '30px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    width: '110px',
    fontWeight: '600',
    fontSize: '14px',
    m: 0,
    pt: '5px',
    pb: '5px',
    pl: '10px',
  },
  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
    border: `1.5px solid ${theme.palette.colors.chiGong}`,
    overflow: 'visible',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

export const IconContainerSx = {
  border: '1px solid rgba(19, 20, 20, 0.5)',
  cursor: 'pointer',
  height: '35px',
  width: '35px',
  borderTopRightRadius: '30px',
  borderBottomRightRadius: '30px',
  borderLeft: 'none',
};

export const LoadingIconSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  top: '9px',
  left: '7px',
  height: '15px',
  width: '15px',
  color: 'rgba(0, 0, 0, 0.26)',
};

export const LoadingIconContainerSx = {
  border: '1px solid rgba(19, 20, 20, 0.5)',
  height: '35px',
  width: '35px',
  borderTopRightRadius: '30px',
  borderBottomRightRadius: '30px',
  borderLeft: 'none',
};
