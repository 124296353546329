export const HeaderContainerSx = {};

export const TooltipContainerSx = {
  color: 'gray',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const TooltipSx = {
  fontSize: '20px',
};
