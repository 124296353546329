import { createSlice } from '@reduxjs/toolkit';
import { GlobalState } from './types';
import {
  setAuthTokenReducer,
  setAuthErrorCodeReducer,
  setGlobalNotificationBannerReducer,
  setGlobalAvatarPopoverReducer,
  setGlobalAuth0CollaboratorReducer,
} from './reducers';
import { setLocalStorageValue } from 'api/storage';
import {
  getCollaboratorFulfilledReducer,
  getCollaboratorPendingReducer,
  getCollaboratorRejectedReducer,
} from './reducers';
import { getCollaborator } from './actions';

const initialState: GlobalState = {
  authToken: 'initial',
  authErrorCode: 0,
  globalNotificationBanner: {
    show: false,
    content: null,
  },
  globalAvatarPopover: {
    show: false,
  },
  globalAuth0Collaborator: undefined,
};

export const globalSlice = createSlice({
  name: 'globalSlice',
  initialState: () => {
    setLocalStorageValue('authToken', 'initial');
    return initialState;
  },
  reducers: {
    setAuthToken: setAuthTokenReducer,
    setAuthErrorCode: setAuthErrorCodeReducer,
    setGlobalAuth0Collaborator: setGlobalAuth0CollaboratorReducer,
    setGlobalNotificationBanner: setGlobalNotificationBannerReducer,
    setGlobalAvatarPopover: setGlobalAvatarPopoverReducer,
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCollaborator.pending, getCollaboratorPendingReducer);
    builder.addCase(getCollaborator.fulfilled, getCollaboratorFulfilledReducer);
    builder.addCase(getCollaborator.rejected, getCollaboratorRejectedReducer);
  },
});

export const {
  setAuthToken,
  setAuthErrorCode,
  setGlobalNotificationBanner,
  setGlobalAvatarPopover,
  setGlobalAuth0Collaborator,
} = globalSlice.actions;
