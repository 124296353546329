import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { UseFormReturn } from 'react-hook-form';
import { InviteAdminFormInput } from './types';

export const handleOnBlurTrim: (
  form: UseFormReturn<InviteAdminFormInput>,
  field: keyof InviteAdminFormInput
) => StandardInputProps['onBlur'] = (form, field) => (event) => {
  const trimmedValue = event.target.value.trim();
  event.target.value = trimmedValue;
  form.setValue(field, trimmedValue);
};
