import React from 'react';
import { DropdownCellProps } from './types';
import Dropdown from 'components/Dropdown';
import { DropdownSx } from './styles';

const DropdownCell = (params: any) => {
  const { row, colDef } = params;
  const { field } = colDef;
  const props: DropdownCellProps = row[field];
  const { options, selected, disabled, hide, onSelectionClick } = props;
  const handleOnSelectionClick = React.useCallback((event: any) => {
    onSelectionClick(event);
  }, []);
  if (hide) return null;
  return (
    <Dropdown
      type="Select"
      items={options}
      disabled={disabled}
      selectedItem={selected}
      // @ts-ignore
      handleSearchOptionChange={handleOnSelectionClick}
      dropdownSx={DropdownSx}
    />
  );
};

export default DropdownCell;
