import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { HeaderContainerSx, TooltipContainerSx, TooltipSx } from './styles';

const TooltipHeader = (params: any) => {
  const {
    header: { data },
    headerName,
  } = params.colDef;
  const { description } = data;
  return (
    <Stack flexDirection="row" sx={HeaderContainerSx}>
      <Stack>
        <b>{headerName}</b>
      </Stack>
      &nbsp;
      <Stack sx={TooltipContainerSx}>
        <Tooltip title={description} placement={'bottom'} arrow={true}>
          <InfoOutlinedIcon sx={TooltipSx} />
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default TooltipHeader;
