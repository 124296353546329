import { SxProps } from '@mui/material';
import { DRAWER_WIDTH } from 'global-styles';

export const ContainerSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  top: '61px',
  alignItems: 'center',
  '@media (min-width: 1440px)': {
    left: `${DRAWER_WIDTH}px`,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
};

export const HeaderSx: SxProps = {
  mt: '20px',
  mb: '20px',
  display: 'flex',
  flexDirection: 'row',
  width: '95%',
};

export const HeaderLeftSx = {
  display: 'flex',
  width: '50%',
};

export const HeaderRightSx = {
  display: 'flex',
  width: '50%',
  alignItems: 'flex-end',
  justifyContent: 'center',
};

export const TableContentSx = {
  display: 'flex',
  flexDirection: 'row',
  width: '95%',
};

export const CTAContentSx = {
  display: 'flex',
  flexDirection: 'row',
  width: '95%',
};

export const TitleSx = {
  fontSize: '24px',
  fontWeight: '500',
};
