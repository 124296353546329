import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';

import { DropdownCellProps } from './types';
import {
  ButtonSx,
  SelectSx,
  IconContainerSx,
  LoadingIconSx,
  LoadingIconContainerSx,
} from './styles';
import {
  useHandleOnMenuItemClick,
  useHandleOnSelectClick,
  useHandleOnClick,
  useHandleToggleDropdown,
} from './hooks';

const ButtonActionOrDropdownCell = (params: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { row, colDef } = params;
  const { field } = colDef;
  const props: DropdownCellProps = row[field];
  const {
    options,
    selected,
    disabled,
    onMenuItemClick,
    onSelectClick,
    text,
    loading,
    hide,
    onButtonClick,
    type,
  } = props;

  const handleOnMenuItemClick = useHandleOnMenuItemClick(
    disabled,
    onMenuItemClick,
    setIsOpen
  );
  const handleOnSelectClick = useHandleOnSelectClick(
    disabled,
    onSelectClick,
    setIsOpen,
    isOpen
  );
  const handleOnClick = useHandleOnClick(disabled, onButtonClick, isOpen);
  const handleToggleDropdown = useHandleToggleDropdown(disabled, setIsOpen);
  if (hide) return null;
  if (type === 'button') {
    return (
      <Stack>
        <LoadingButton
          sx={ButtonSx}
          onClick={handleOnClick}
          loading={loading}
          disabled={disabled}
        >
          {text}
        </LoadingButton>
      </Stack>
    );
  }
  if (type === 'dropdown') {
    return (
      <Select
        type="Select"
        disabled={disabled || loading}
        value={selected}
        onClick={handleOnSelectClick}
        open={isOpen}
        sx={SelectSx}
        IconComponent={() => {
          if (loading) {
            return (
              <Stack sx={LoadingIconContainerSx}>
                <CircularProgress sx={LoadingIconSx} size="small" />
              </Stack>
            );
          }
          return (
            <ArrowDropDownIcon
              onClick={handleToggleDropdown} // Toggle the dropdown on click
              fontSize="small"
              sx={IconContainerSx}
            />
          );
        }}
        SelectDisplayProps={{
          style: { pointerEvents: 'auto' }, // Re-enable pointer events for the text
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={handleOnMenuItemClick}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  }
  return null;
};

export default ButtonActionOrDropdownCell;
