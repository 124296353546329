import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { bffClient } from 'api/apollo';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import {
  useSetAuthTokenToReduxFromLocalStorage,
  useAuth0StateMachine,
} from './hooks';
import {
  selectAuthErrorCode,
  selectAuthToken,
} from 'store/slices/global/selectors';
import { LoadingContainerSx } from './styles';
import Auth0ErrorPage from '../pages/Auth0ErrorPage';

const BffProvider = (props: { children: React.ReactNode }) => {
  const authToken = useSelector(selectAuthToken);
  const authErrorCode = useSelector(selectAuthErrorCode);
  const { isAuthenticated, isLoading } = useAuth0();

  const shouldRenderApp =
    !['initial', 'logged-out', 'unauthenticated'].includes(authToken) &&
    isAuthenticated;

  useSetAuthTokenToReduxFromLocalStorage();
  useAuth0StateMachine();

  if (process.env.NODE_ENV === 'test') {
    return <ApolloProvider client={bffClient}>{props.children}</ApolloProvider>;
  }

  if ([404, 410, 500].includes(authErrorCode)) {
    return <Auth0ErrorPage type={authErrorCode} />;
  }

  if (shouldRenderApp) {
    return <ApolloProvider client={bffClient}>{props.children}</ApolloProvider>;
  } else if (isLoading) {
    return (
      <Stack sx={LoadingContainerSx}>
        <CircularProgress />
      </Stack>
    );
  }
  return null;
};

export default BffProvider;
