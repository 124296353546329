import { RootState } from 'store';

export const selectManageUsersCredentialedCollaborators = (state: RootState) =>
  state.manageUsers.table.credentialedCollaborators;

export const selectManageUsersCredentialedCollaboratorsCount = (
  state: RootState
) => state.manageUsers.table.credentialedCollaboratorsCount;

export const selectGetCredentialedCollaboratorsLoadingState = (
  state: RootState
) => state.manageUsers.table.getCredentialedCollaboratorsLoadingState;

export const selectPatchCredentialedCollaboratorsLoadingState = (
  state: RootState
) => state.manageUsers.table.patchCredentialedCollaboratorsLoadingState;

export const selectPostCredentialedCollaboratorsLoadingState = (
  state: RootState
) => state.manageUsers.table.postCredentialedCollaboratorsLoadingState;

export const selectPostCredentialedCollaboratorsErrorCode = (
  state: RootState
) => state.manageUsers.table.postCredentialedCollaboratorsErrorCode;

export const selectPostCredentialedCollaboratorsLoadingStateMap = (
  state: RootState
) => state.manageUsers.table.postCredentialedCollaboratorsLoadingStateMap;

export const selectCredentialedCollaboratorsPaginationModel = (
  state: RootState
) => state.manageUsers.table.paginationModel;

export const selectShowRoleChangeConfirmationModal = (state: RootState) =>
  state.manageUsers.table.showRoleChangeConfirmationModal;

export const selectRoleConfirmationData = (state: RootState) =>
  state.manageUsers.table.roleConfirmationData;

export const selectShowRevokeConfirmationModal = (state: RootState) =>
  state.manageUsers.table.showRevokeConfirmationModal;

export const selectButtonActionDropdownById = (state: RootState) =>
  state.manageUsers.table.buttonActionDropdownById;
