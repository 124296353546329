import { createAsyncThunk } from '@reduxjs/toolkit';
import * as restCredentialedCollaboratorsAPI from 'api/rest/credentialedCollaborators';
import { CredentialedCollaboratorBody } from 'api/rest/credentialedCollaborators/types';

export const getCredentialedCollaborators = createAsyncThunk(
  'manage_users/table/getCredentialedCollaborators',
  async (params: { orgId: string; offset: number; limit: number }) => {
    return restCredentialedCollaboratorsAPI.getCredentialedCollaborators(
      params
    );
  }
);

export const patchCredentialedCollaborators = createAsyncThunk(
  'manage_users/table/patchCredentialedCollaborators',
  async (params: {
    collaboratorId: string;
    data: Partial<CredentialedCollaboratorBody>;
  }) => {
    const { collaboratorId, data } = params;
    return restCredentialedCollaboratorsAPI.patchCredentialedCollaborators({
      collaboratorId,
      data,
    });
  }
);

export const postCredentialedCollaborators = createAsyncThunk(
  'manage_users/table/postCredentialedCollaborators',
  async (params: { data: CredentialedCollaboratorBody }) => {
    const { data } = params;
    return restCredentialedCollaboratorsAPI.postCredentialedCollaborators({
      data,
    });
  }
);
