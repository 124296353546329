import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { ThemeProvider } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { NavigationContext, Navigator } from 'context/NavigationContext';
import Auth0ProviderWithRedirectCallback from 'context/Auth0ProviderWithRedirectCallback';
import AppBar from 'components/AppBar';
import Alert from 'components/Alert';
import GlobalLoadingIndicator from 'components/GlobalLoadingIndicator';
import Sidebar from 'components/Sidebar';
import Routes from './routes';
import theme from 'themes/theme';
import './index.css';
import '@fontsource/inter';
import { CssBaseline } from '@mui/material';
import { store } from 'store';
import Head from 'components/Head';
import BffProvider from 'context/BffProvider';
import BillingProvider from 'context/BillingModalProvider';
import { SiteMaintenanceProvider } from 'context/SiteMaintenanceContext';
import BillingStatusDialog from 'components/BillingStatusModal';
import GlobalToastNotification from 'components/GlobalToastNotification';
import RetrievalServiceProvider from 'context/RetrievalServiceProvider';
import FeatureFlagConfigProvider from 'context/FeatureFlagConfigProvider';
import NotificationBanner from 'components/banners/NotificationBanner';
import * as Sentry from 'vendor/sentry';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const App = () => {
  const navigator = new Navigator('/');

  Sentry.init();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  LicenseInfo.setLicenseKey(
    process.env.REACT_APP_MUI_PRO_LICENSE_KEY as string
  );

  return (
    <React.StrictMode>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback>
          <Provider store={store}>
            <BffProvider>
              <NavigationContext.Provider value={navigator}>
                <ThemeProvider theme={theme}>
                  <QueryClientProvider client={queryClient}>
                    <FeatureFlagConfigProvider />
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Head />
                      <Alert />
                      <BillingProvider>
                        <SiteMaintenanceProvider>
                          <Stack flexDirection="column">
                            <Stack sx={{ display: 'flex', width: '100vw' }}>
                              <NotificationBanner />
                            </Stack>
                            <Stack>
                              <Stack sx={{ display: 'flex' }}>
                                <CssBaseline />
                                <AppBar />
                                <Sidebar />
                              </Stack>
                              <BillingStatusDialog />
                              <GlobalLoadingIndicator />
                              <GlobalToastNotification />
                              <RetrievalServiceProvider>
                                <Routes />
                              </RetrievalServiceProvider>
                            </Stack>
                          </Stack>
                        </SiteMaintenanceProvider>
                      </BillingProvider>
                    </LocalizationProvider>
                  </QueryClientProvider>
                </ThemeProvider>
              </NavigationContext.Provider>
            </BffProvider>
          </Provider>
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </React.StrictMode>
  );
};

const AppWithFeatureFlag = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string,
})(App);

// replace with <App /> when/if feature flagging is removed
root.render(<AppWithFeatureFlag />);
