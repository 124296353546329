export enum DevicesSearchBy {
  SERIAL_NO = 'serialNumber',
  ASSET_ID = 'assetNumber',
  ASSIGNEE_NAME = 'assigneeName',
}
export enum GetDevicesAutocompleteByQuery {
  SEARCH_ASSETS = 'searchAssets',
  COLLABORATORS = 'collaborators',
}
const SEARCH_BY = 'Search by';
export const DEVICES_SEARCH_BY_DEFAULT = DevicesSearchBy.SERIAL_NO;
export const DEVICES_SEARCH_TERM_TYPES = Object.values(DevicesSearchBy);
export const DEVICES_OPTIONS_TABLE = {
  [DevicesSearchBy.SERIAL_NO]: {
    textPlaceholder: `${SEARCH_BY} serial number`,
    selectName: 'Serial number',
    searchQueryName: GetDevicesAutocompleteByQuery.SEARCH_ASSETS,
    searchQueryField: DevicesSearchBy.SERIAL_NO,
    searchAssetsQueryField: DevicesSearchBy.SERIAL_NO,
    // WARNING: removing ": " from option prefix will break autocomplete's useEffect that prevents add'l querying
    optionPrefix: 'Serial number: ',
  },
  [DevicesSearchBy.ASSET_ID]: {
    textPlaceholder: `${SEARCH_BY} asset ID`,
    selectName: 'Asset ID',
    searchQueryName: GetDevicesAutocompleteByQuery.SEARCH_ASSETS,
    searchQueryField: DevicesSearchBy.ASSET_ID,
    searchAssetsQueryField: DevicesSearchBy.ASSET_ID,
    optionPrefix: 'Asset ID: ',
  },
  [DevicesSearchBy.ASSIGNEE_NAME]: {
    textPlaceholder: `${SEARCH_BY} assignee name`,
    selectName: 'Assignee name',
    searchQueryName: GetDevicesAutocompleteByQuery.COLLABORATORS,
    searchQueryField: 'firstOrLastName',
    searchAssetsQueryField: 'assigneeEmail',
    optionPrefix: 'Assignee name: ',
  },
};

export enum DevicesSearchBySelect {
  ID = 'select-devices-search-by-select',
}

export const DEVICES_SEARCH_BAR_CLEAR_INDICATOR_ID =
  'devices-search-bar-clear-indicator';
export const DEVICES_SEARCH_BY_SELECT_ID = 'select-devices-search-by-select';
export const SEARCH_BAR_EXTERNAL_RADIUS = '50px';
export const SEARCH_BAR_TYPE_SELECT_WIDTH = '162px';
export const SEARCH_BAR_WHOLE_WIDTH = '550px';
export const SEARCH_BAR_WHOLE_HEIGHT = '36px';

export enum AssetStatusTransitionEnum {
  ACTIVATION_LOCKED = 'Activation Locked',
  RECOVERY_LOCKED = 'Recovery Locked',
  ALLOCATED = 'Allocated',
  DONATED = 'Donated',
  HOLD = 'HOLD',
  IN_INVENTORY = 'In Inventory',
  IN_INVENTORY_DEVICE_ISSUE = 'In inventory - device issue',
  LOST = 'Lost',
  MDM_LOCKED = 'MDM Locked',
  PENDING_ALLOCATION = 'Pending Allocation',
  PENDING_CERTIFICATE = 'Pending Certificate',
  PENDING_QUOTE_APPROVAL = 'Pending Quote Approval',
  PENDING_RECYCLE = 'Pending Recycle',
  PENDING_REPAIR = 'Pending Repair',
  PENDING_RETRIEVAL = 'Pending Retrieval',
  PENDING_SALE = 'Pending Sale',
  PENDING_WIPE = 'Pending Wipe',
  PROCESSING_DEVICE = 'Processing Device',
  QUOTE_IN_PROGRESS = 'Quote in Progress',
  RECYCLED = 'Recycled',
  SOLD = 'Sold',
  TRANSFERRED = 'Transferred',
  INTAKE = 'Intake',
}

export const READ_ONLY_ASSET_TRANSITION_STATUSES = [
  ...Object.keys(AssetStatusTransitionEnum),
] as const;

export const COLUMNS_TO_FIELDS_MAP: { [index: string]: string } = {
  'asset-number': 'assetNumber',
  type: 'type',
  make: 'make',
  model: 'model',
  condition: 'cosmeticCondition',
  'keyboard-configuration': 'keyboard',
  'depot-location': 'depotLocation',
  'purchase-date': 'purchaseDate',
  status: 'status',
  'updated-at': 'updatedAt', // 'last-updated-at' is not a valid key in the store
  assignee: 'assignee',
  color: 'color',
  'date-of-last-check-in': 'dateOfLastCheckIn',
  'date-of-last-check-out': 'dateOfLastCheckOut',
  'device-issue': 'deviceAssessment',
  'display-size': 'displaySize',
  memory: 'memory',
  'personal-email': 'personalEmail',
  processor: 'processor',
  'serial-number': 'serialNumber',
  storage: 'storage',
  'work-email': 'workEmail',
  notes: 'details',
};

export const FIELDS_TO_COLUMNS_MAP: { [index: string]: string } = {
  assetNumber: 'asset-number',
  type: 'type',
  make: 'make',
  model: 'model',
  cosmeticCondition: 'condition',
  keyboard: 'keyboard-configuration',
  depotLocation: 'depot-location',
  purchaseDate: 'purchase-date',
  status: 'status',
  updatedAt: 'updated-at',
  assignee: 'assignee',
  color: 'color',
  dateOfLastCheckIn: 'date-of-last-check-in',
  dateOfLastCheckOut: 'date-of-last-check-out',
  deviceAssessment: 'device-issue',
  displaySize: 'display-size',
  memory: 'memory',
  personalEmail: 'personal-email',
  processor: 'processor',
  serialNumber: 'serial-number',
  storage: 'storage',
  workEmail: 'work-email',
  details: 'notes',
};

export const COLUMNS_DEFAULT_VISIBILITY: { [index: string]: boolean } = {
  assetNumber: true,
  type: true,
  make: true,
  model: true,
  cosmeticCondition: true,
  keyboard: true,
  depotLocation: true,
  purchaseDate: true,
  status: true,
  updatedAt: true,
  assignee: true,
  color: true,
  deviceAssessment: true,
  displaySize: true,
  memory: true,
  processor: true,
  serialNumber: true,
  storage: true,
  dateOfLastCheckIn: true,
  dateOfLastCheckOut: true,
  personalEmail: true,
  workEmail: true,
  details: true,
};
