import { GridColDef } from '@mui/x-data-grid-pro';
import { CustomDataGridProProps } from './types';
import cells from '../cells';
import headers from '../headers';

export const configureColumns = (
  columns: CustomDataGridProProps['columns']
) => {
  return columns.map((c) => {
    const column: GridColDef = {
      ...c,
      renderCell: cells[c.cellType],
    };
    if (c?.header?.headerType) {
      column.renderHeader = headers[c.header.headerType];
    }
    return column;
  });
};
