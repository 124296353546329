export const PRIMARY_ADMIN_COPY = (
  <>
    <b>Primary admin:</b> can access the store, place orders, and manage other
    admins' access.
  </>
);
export const SECONDARY_ADMIN_COPY = (
  <>
    <b>Secondary admin:</b> can access the store and place orders.
  </>
);
